import React from 'react';

function MarginSquareSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33" fill="none">
            <path d="M1 5L1 26.3333" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M33 5V26.3333" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M27.6665 1L6.33317 0.999998" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M27.6665 31.6666L6.33317 31.6666" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export default MarginSquareSvg;