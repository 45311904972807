import React from 'react';

function MarginLeftRightSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
            <mask id="path-1-inside-1_622_1153" fill="white">
                <rect x="6" y="6.5" width="14" height="13" rx="0.9"/>
            </mask>
            <rect x="6" y="6.5" width="14" height="13" rx="0.9" stroke="black" strokeWidth="4" mask="url(#path-1-inside-1_622_1153)"/>
            <path d="M1 1.5L1 25.5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <path d="M25 1.5V25.5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export default MarginLeftRightSvg;