import React from 'react';

function MarginLeftSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26" fill="none">
            <mask id="path-1-inside-1_625_1517" fill="white">
                <rect x="6" y="6" width="14" height="13" rx="0.9"/>
            </mask>
            <rect x="6" y="6" width="14" height="13" rx="0.9" stroke="black" strokeWidth="4" mask="url(#path-1-inside-1_625_1517)"/>
            <path d="M1 1L1 25" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export default MarginLeftSvg;