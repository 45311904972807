import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {createBrowserRouter, RouterProvider, useNavigate,} from "react-router-dom";
import ErrorPage from "./error-page";
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import ProjectList from "./pages/ProjectList";
import PhotoPreview from "./pages/PhotoPreview";
import Loading from "./pages/Loading";
import PreviewOnSheet from "./pages/PreviewOnSheet";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {Spin} from "antd";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {getUserAction, loginAction, setLoadingAction} from "./redux/pageSlice";
import styled from "styled-components";
import Settings from "./pages/Settings";


const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-dot {
    position: fixed !important;
  }
`;

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
             {
                path: "users",
                element: <UserList/>,
            }, {
                path: "projects",
                element: <ProjectList/>,
            }, {
                path: "loading/:id",
                element: <Loading/>,
            }, {
                path: "preview/:id/:tab",
                element: <PhotoPreview/>,
            }, {
                path: "generated/:id/:tab",
                element: <PreviewOnSheet/>,
            },{
                path: "settings",
                element: <Settings/>,
            },
        ],
    },
]);

const loadingRouter = createBrowserRouter([
    {
        path: "*",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "*",
                element: <SpinWrapper><Spin size={"large"}/></SpinWrapper>,
            }
        ],
    },
]);

const loginRouter = createBrowserRouter([
    {
        path: "*",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "*",
                element: <Login/>,
            }
        ],
    },
]);

const AppWrap = () => {

    const loading = useAppSelector(state => state.page.loading)
    const user = useAppSelector(state => state.page.user)
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserAction())

    }, [])

    return (
        <StyledSpin spinning={loading}>
            <RouterProvider router={user?.Login ? router : loginRouter}/>
        </StyledSpin>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <AppWrap/>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
