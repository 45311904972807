import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import TrashCanSvg from "../images/trashCanSvg";
import {Modal} from "antd";
import SearchSvg from "../images/SearchSvg";
import {deleteProcessingAction, getProcessingAction, getProcessingsAction} from "../redux/pageSlice";
import {useDispatch} from "react-redux";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useNavigate} from "react-router-dom";
import {scale} from '../config';

const Component = styled.div`
  width: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-bottom: calc(82px * ${scale});
  margin-top: calc(30px * ${scale});

  border-radius: calc(20px * ${scale});
  border: calc(3px * ${scale}) solid #1C1C1C;
`;

const Tr = styled.tr`


  & > th, & > td {
    width: 25%;

    &:first-child {
      width: 33%;
      padding-left: calc(100px * ${scale});
    }

    &:last-child {
      padding-right: calc(80px * ${scale});
    }
  }

  &:hover > td {
    background: rgba(225, 181, 90, 0.30);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & > thead {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% - calc(112px * ${scale}));
      margin: 0 calc(56px * ${scale});
      bottom: calc(30px * ${scale});
      border-bottom: calc(1px * ${scale}) solid #1C1C1C;
    }
  }

  & > tbody > ${Tr} {
    cursor: pointer;
  }
`;


const Th = styled.th`
  padding: calc(8px * ${scale});
  text-align: left;
  height: calc(117px * ${scale});

  color: #A5A5A5;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale} */
  text-transform: uppercase;
`;

const Td = styled.td`
  padding: calc(8px * ${scale});
  height: calc(60px * ${scale});

  color: #1C1C1C;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale} */
`;


const Action = styled.div`
  border-radius: calc(10px * ${scale});
  background: #E1B55A;

  display: flex;
  width: calc(100px * ${scale});
  height: calc(39px * ${scale});
  justify-content: center;
  align-items: center;

  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale} */

  cursor: pointer;
  user-select: none;

  &:hover {
    background: #DB5C34;
  }
`;

const ModalDelete = styled(Modal)`
`;

const ModalDeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 calc(50px * ${scale});

  color: #FAF3E9;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-weight: 500;
  line-height: 140%;

  & > svg {
    width: calc(80px * ${scale});
    height: calc(80px * ${scale});

    & > path {
      stroke: #FAF3E9;
    }
  }

  & > b {
    margin: calc(30px * ${scale}) 0 calc(20px * ${scale});

    font-family: Krona One, serif;
    font-size: calc(18px * ${scale});
    font-weight: 400;
  }
`;

const ModalDeleteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(50px * ${scale});
  width: calc(510px * ${scale});
`;

const ModalDeleteButton = styled.div`
  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale} */

  display: flex;
  width: calc(245px * ${scale});
  padding: calc(17px * ${scale}) calc(36px * ${scale});
  justify-content: center;
  align-items: center;

  border-radius: calc(20px * ${scale});
  background: #E1B55A;

  cursor: pointer;

  &:hover {
    background: #DB5C34;
  }

  &:first-child {
    color: #FAF3E9;

    border: calc(3px * ${scale}) solid #E1B55A;
    background: #0000;

    &:hover {
      border: calc(3px * ${scale}) solid #DB5C34;
    }
  }
`;

const SearchInputWrapper = styled.label`
  width: calc(484px * ${scale});
  height: calc(60px * ${scale});
  padding: calc(19px * ${scale}) calc(24px * ${scale});

  border-radius: calc(10px * ${scale});
  border: calc(3px * ${scale}) solid #1C1C1C;

  display: flex;
  align-items: center;
  gap: calc(10px * ${scale});
  cursor: text;
  
  & > svg {

    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
  }
`;

const SearchInput = styled.input`

  color: #1C1C1C;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale} */
  text-transform: uppercase;
  background: #0000;

  border: 0;
  outline: none;
  height: calc(24px * ${scale});
`;

function TableRow({data, showModalDelete}: { data: any, showModalDelete: (e: any, Id: number | string) => void }) {

    const navigate = useNavigate();

    function formatDate(oldFormatDate: string) {
        let date = new Date(oldFormatDate);

        let day: any = date.getDate();
        let month: any = date.getMonth() + 1;
        let year: any = date.getFullYear();

        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;

        return `${day}.${month}.${year}`;
    }

    const clickHandler = (id: number, tab: string) => {
        navigate(`/preview/${id}/${tab}`);
    }

    return (
        <Tr onClick={() => {
            clickHandler(data?.Id, Object.keys(data?.Figures)?.[0])
        }}>
            <Td>{data?.Name}</Td>
            <Td>{formatDate(data?.CreatedDate)}</Td>
            <Td>{formatDate(data?.UpdatedDate)}</Td>
            <Td>
                <Action onClick={(e: any) => showModalDelete(e, data.Id)}>Delete</Action>
            </Td>
        </Tr>
    );
};

function TableProject() {

    const processings = useAppSelector(state => state.page.processings)
    const dispatch = useAppDispatch();

    useEffect(() => {

        dispatch(getProcessingsAction())
    }, [])

    const [openDelete, setOpenDelete] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // delete
    const [IdDelete, setIdDelete] = useState<number | string>("");

    const showModalDelete = (e: any, Id: number | string) => {
        e.stopPropagation()
        setIdDelete(Id)
        setOpenDelete(true);
    };
    const handleDeleteOk = () => {
        dispatch(deleteProcessingAction(IdDelete))
        setOpenDelete(false);
    };
    const handleDeleteCancel = () => {
        setOpenDelete(false);
    };

    // Search
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    }

    const filteredData = processings.filter((item) => {
        return item.Name.toLowerCase().includes(searchTerm.toLowerCase());
    });


    return (
        <Component>
            <ModalDelete
                classNames={{footer: "modalActionFooter"}}
                open={openDelete}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
                centered
                closeIcon={null}
                styles={{
                    mask: {background: "#0003"},
                    content: {
                        borderRadius: `calc(30px * ${scale})`,
                        padding: `calc(50px * ${scale}) 0 calc(44px * ${scale})`,
                        background: "#1C1C1C"
                    },
                    footer: {display: "none", justifyContent: "center"}
                }}
                okText={"Delete"}
                width={`calc(780px * ${scale})`}
            >
                <ModalDeleteContent>
                    <TrashCanSvg/>
                    <b>Are you sure delete this project?</b>
                    If you delete the project you can't recover it.
                    <ModalDeleteButtons>
                        <ModalDeleteButton onClick={handleDeleteCancel}>Cancel</ModalDeleteButton>
                        <ModalDeleteButton onClick={handleDeleteOk}>Delete</ModalDeleteButton>
                    </ModalDeleteButtons>
                </ModalDeleteContent>
            </ModalDelete>

            <SearchInputWrapper>
                <SearchSvg/>
                <SearchInput type="text" onChange={handleSearch}/>
            </SearchInputWrapper>

            <TableWrapper>
                <Table>
                    <thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>creation date</Th>
                        <Th>modification date</Th>
                        <Th/>
                    </Tr>
                    </thead>
                    <tbody>
                    {filteredData.map((data, index) => {
                        return <TableRow key={index} data={data} showModalDelete={showModalDelete}/>;
                    })}
                    </tbody>
                </Table>
            </TableWrapper>
        </Component>
    );
}

export default TableProject;