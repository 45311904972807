import './App.css';
import styled from "styled-components";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import {useAppSelector} from "./redux/hooks";
import {RoleType} from "./utils/types";
import {scale} from "./config";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #d2d2d0;
  display: flex;
  justify-content: center;
`;

const Pages = styled.div`
  background: #FAF3E9;
  //background: #460404;

  width: min(1920px, 100%);
  min-width: calc(1200px * ${scale});

  min-height: 100%;
  display: flex;
  justify-content: center;
`;

function App() {
    const user = useAppSelector(state => state.page.user)

    const navigate = useNavigate();

    const myElementRef = useRef<any>();

    useEffect(() => {

        const element = myElementRef.current;

        if (element && element.innerHTML === "") {
            if(user?.Login) {
                switch (user?.Role){
                    case RoleType.admin: {
                        navigate("/users");
                        return
                    }
                    case RoleType.user: {
                        navigate("/settings");
                        return;
                    }
                }
            }
        }

    }, [user])

    return (
        <StyledApp>
            <Pages ref={myElementRef}>
                <Outlet/>
            </Pages>
        </StyledApp>
    );
}

export default App;
