import React, {useState} from "react";
import {ColorPicker, Form, Radio} from "antd";
import CheckedSvg from "../images/CheckedSvg";
import styled from "styled-components";
import {scale} from "../config";

const ColorPikerFormItem = styled(Form.Item)`

  &.ant-form-item {
    height: calc(245px * ${scale}) !important;
    padding-top: calc(35px * ${scale});
    border-top: calc(2px * ${scale}) solid rgba(165, 165, 165, 0.20);

    & .ant-radio-group {
      display: grid;
      grid-template-columns: repeat(6, max-content);
      gap: calc(15px * ${scale});
    }
  }
`;

const ColorWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    height: calc(20px * ${scale});
    width: calc(20px * ${scale});
    pointer-events: none;
    display: none;

    background: #0005;
    border-radius: 100%;
    padding: 2px;
  }
`;

const ColorPikerRadio = styled(Radio)<{ $color: string }>`
  background: ${props => props.$color};
  width: calc(33px * ${scale});
  height: calc(33px * ${scale});
  border-radius: calc(100px * ${scale});
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: ${props => props.$color === "#ffffff" || props.$color === "#ffffff00" ? `calc(1px * ${scale}) solid #000` : ""};
  
  & .ant-radio {
    display: none;
  }

  &.ant-radio-wrapper-checked {
    
    & + svg {
      display: block;
    }
  }
`;

const StyledColorPicker = styled(ColorPicker)<{ $isColorPickerSelected: boolean }>`
  width: calc(33px * ${scale});
  height: calc(33px * ${scale});
  min-width: auto;
  border: 0;
  background: #0000;
  box-shadow: 0 0 0 0 #0000;
  border-radius: 0;
  padding: 0;

  & > .ant-color-picker-color-block,
  & > .ant-color-picker-clear {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 #0000;
    border-radius: calc(100px * ${scale});
    border: 0;
  }

  & > .ant-color-picker-clear, & > .ant-color-picker-color-block > .ant-color-picker-color-block-inner {
    background: conic-gradient(#ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8a2be2, #ff0000) !important;
    border: 0 solid #0000;
  }

  & > .ant-color-picker-clear:after {
    display: none;
  }
`;


const colors = [
    "#9BAECE", "#143D74", "#453F41", "#BB468D", "#D11313", "#FF3D00",
    "#A7BD67", "#F5F28A", "#E1B55A", "#DB5C34", "#FF8A00", "#7D201B",
    "#0F98E5", "#151DDA", "#6A48F1", "#A111F8", "#E312E8", "#000000",
    "#ffffff", "#B5D7D5", "#127121", "#449A86", "#14E8E8",
]

function FormItemColorPiker(props: any) {
    const label = props.label
    const name = props.name
    const form = props.form

    const [isColorPickerSelected, setIsColorPickerSelected] = useState<boolean>(false)

    return (
        <ColorPikerFormItem
            {...props}
            label={label}
            name={name}
        >
            <Radio.Group onChange={() => {
                setIsColorPickerSelected(false)
            }}>

                {colors.map((value, index) => {
                    return (
                        <ColorWrap>
                            <ColorPikerRadio key={index} $color={value} value={value}/>
                            <CheckedSvg/>
                        </ColorWrap>)
                })}

                <ColorWrap>
                    <StyledColorPicker
                        $isColorPickerSelected
                        onChangeComplete={(color) => {
                            form.setFieldValue(name, color?.toHexString())
                            setIsColorPickerSelected(true)
                        }}/>
                    <CheckedSvg style={{display: isColorPickerSelected ? "block" : "none"}}/>
                </ColorWrap>

            </Radio.Group>
        </ColorPikerFormItem>
    )
}
export default FormItemColorPiker;