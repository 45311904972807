import {configureStore} from "@reduxjs/toolkit";
import {logger} from "redux-logger";
import pageReducer from "./pageSlice";

export const store = configureStore({
    reducer: {
        page: pageReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    // @ts-ignore
    middleware: (getDefaultMiddleware) => process.env.NODE_ENV !== 'production' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch