import React from 'react';
import styled from "styled-components";
import TopMenuUser from "../components/TopMenuUser";
import SettingsForm from "../components/SettingsForm";
import { scale } from '../config';

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(112px * ${scale});
`;

const PageContent = styled.div`
  max-width: calc(1180px * ${scale});
  width: 100%;
  margin-top: calc(110px * ${scale});
`;

function Settings() {
    return (
        <Page>
            <TopMenuUser type={"menu2"}/>
            <PageContent>
                <SettingsForm/>
            </PageContent>
        </Page>
    );
}

export default Settings;