import React, {useState} from 'react';
import styled from "styled-components";
import {Button, ConfigProvider, Form, Input, InputNumber, Select, Switch, Upload, UploadFile} from "antd";
import ChevronDownSvg from "../images/chevronDownSvg";
import PointerCloudSvg from "../images/PointerCloudSvg";
import PlusInCircleSvg from "../images/plusInCircleSvg";
import {Option} from "antd/es/mentions";
import {useAppDispatch} from "../redux/hooks";
import {newProcessingAction, uploadLogoAction} from "../redux/pageSlice";
import {useNavigate} from "react-router-dom";
import {scale} from '../config';
import FormItemColorPiker from './FormItemColorPiker';

const Component = styled.div`
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-form-item {
    height: calc(96px * ${scale});
    margin: 0;

    .ant-row {

      flex-flow: row;
      justify-content: space-between;

      .ant-form-item-label {
        display: flex;
        align-items: center;

        & label {
          color: #787878;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.calc(4px * ${scale}) */
          text-transform: uppercase;
        }
      }

      & .ant-form-item-control {
        width: calc(300px * ${scale});
        height: calc(60px * ${scale});
        flex: 0 1 auto;

        & .ant-input-number {
          border: 0;
          width: 100%;
          height: 100%;
          background: #0000;

          & .ant-input-number-handler-wrap {
            display: none;
          }

          input {
            background: #FAF3E9;
          }
        }

        & input {
          height: 100%;
          border-radius: calc(20px * ${scale});
          border: calc(2px * ${scale}) solid #1C1C1C;
          padding: calc(19px * ${scale}) calc(24px * ${scale});

          color: #1C1C1C;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.calc(4px * ${scale}) */
        }


        & .ant-form-item-control-input {
          height: 100%;

          & .ant-form-item-control-input-content {
            height: 100%;

            & .ant-select {
              height: 100%;

              .ant-select-arrow {
                svg {

                  width: calc(24px * ${scale});
                  height: calc(24px * ${scale});
                }

                inset-inline-end: calc(24px * ${scale});
              }

            }

          }
        }

        & .ant-select-selector {
          border-radius: calc(20px * ${scale});
          border: calc(2px * ${scale}) solid #1C1C1C !important;

          width: 100%;

          padding: calc(19px * ${scale}) calc(24px * ${scale});
          color: #1C1C1C;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.calc(4px * ${scale}) */
        }
      }
    }
  }

  .height50 {
    .ant-row {
      & .ant-form-item-control {
        height: calc(50px * ${scale});
      }
    }

  }


  .short .ant-row .ant-form-item-control .ant-form-item-control-input {
    width: calc(180px * ${scale});

    & .ant-select-selector {
      width: calc(180px * ${scale});
    }
  }

  .slightlyLess .ant-row .ant-form-item-control {
    width: calc(430px * ${scale});

    & .ant-select-selector {
      width: calc(430px * ${scale});
    }
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-17sses9).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {

    border: calc(2px * ${scale}) solid #DB5C34;
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-checked .ant-checkbox-inner {

    border: calc(2px * ${scale}) solid #DB5C34;
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {

    background: #DB5C34;
  }

  .checkbox .ant-row .ant-form-item-control {
    width: auto;

    label {
      width: calc(34px * ${scale});
      height: calc(34px * ${scale});

      & > span {
        width: 100%;
        height: 100%;

        &.ant-checkbox-checked > span {
          background: #DB5C34;
          border-color: #0000;

          &:after {
            transform: rotate(45deg) scale(1.7) translate(-20%, -60%);
          }
        }

        & > span {
          width: 100%;
          height: 100%;
          border-radius: calc(10px * ${scale});
          border-color: #1C1C1C;
          background: #FAF3E9;

          &:after {
            transform: rotate(45deg) scale(1.7) translate(-20%, -60%);
          }
        }
      }
    }
  }

  .ant-upload-wrapper {
    display: block;
    width: 100%;

    .ant-upload {
      display: block;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  button[type="submit"] {
    color: #000;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 23.calc(8px * ${scale}) */

    display: flex;
    width: calc(213px * ${scale});
    height: calc(60px * ${scale});
    padding: calc(18px * ${scale}) calc(80px * ${scale});
    justify-content: center;
    align-items: center;
    margin-top: 0;

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    &:hover {
      color: #000 !important;
      background: #DB5C34 !important;
    }
  }

  .ant-upload-list {
    display: none;

    .ant-upload-list-item {
      margin: 0;
      height: calc(60px * ${scale}) !important;
      font-size: calc(14px * ${scale});

      .ant-upload-list-item-name {
        padding-left: 48px;
      }
    }
  }

  .ant-form-item-has-success {
    .ant-upload-list {
      display: block;

      .ant-upload-list-item-thumbnail {
        position: absolute;
        max-height: 100%;
        height: 100%;
      }
    }
  }
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`

  border-radius: calc(20px * ${scale});
  background: #1C1C1C;
  padding: calc(20px * ${scale}) 0;
  margin-top: calc(25px * ${scale});
  position: relative;

  & > svg {

    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
    bottom: calc(100% - 8px * ${scale});

    position: absolute;
    right: calc(19px * ${scale});

    path {
      fill: #1C1C1C;
    }
  }

  .ant-select-item {

    padding: calc(10px * ${scale}) calc(24px * ${scale});
    border-radius: 0;

    &.ant-select-item-option-active {
      background: #fff1;
    }

    &.ant-select-item-option-selected {
      background: #fff2;
    }

    .ant-select-item-option-content {
      color: #A5A5A5;
      font-family: Roboto, serif;
      font-size: calc(16px * ${scale});
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.calc(4px * ${scale}) */
    }
  }
`;

const ButtonSaveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: calc(60px * ${scale});
`;

const ButtonSave = styled(Form.Item)`
  width: calc(213px * ${scale});
  height: calc(60px * ${scale});

  & .ant-col {
    margin: 0;
    max-width: 100%;
  }

  & button {
    width: 100%;
    height: calc(60px * ${scale});

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    color: #000 !important;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 23.calc(8px * ${scale}) */

    &:hover {
      background: #DB5C34 !important;
    }
  }
`;

const StyledSwitchWrapper = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledSwitch = styled(Switch)`

  border: calc(2px * ${scale}) solid #1C1C1C;
  background: #0000 !important;

  & .ant-wave {
    display: none;
  }

  .ant-switch-inner {
    & > span {
      height: 100%;

      color: #1C1C1C !important;
      font-family: Roboto, serif !important;
      font-size: calc(14px * ${scale}) !important;
      font-weight: 500 !important;
    }
  }

  .ant-switch-inner-checked {
    padding-left: calc(11px * ${scale});
    display: flex !important;
    align-items: center;
    justify-content: flex-start !important;
  }

  .ant-switch-inner-unchecked {
    padding-top: calc(5px * ${scale});
    padding-right: calc(11px * ${scale});
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
  }

`;

const UploadText = styled.div`
  display: flex;
  width: 100%;
  height: calc(60px * ${scale});
  justify-content: center;
  align-items: center;
  gap: calc(10px * ${scale});

  border-radius: calc(20px * ${scale});
  border: calc(3px * ${scale}) solid #E1B55A;

  cursor: pointer;

  color: #000;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-weight: 500;

  svg {
    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
  }

`;

const FormColumn = styled.div`
  width: calc(500px * ${scale});
`;

const normFile = (e: any) => {
    return e?.file;
};

const StyleSelect = {background: "#0000", boxShadow: "0 0 0 0", padding: 0}

function SettingsForm() {

    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const onFinish = (values: any) => {

        const newValues = {
            ...values,
            PageSize: [210, 297],
            PageMargins: [5, 5, 5, 5]
        }

        const newLogo = fileList[0]
        delete newValues.Logo

        dispatch(newProcessingAction({...newValues})).unwrap().then((e) => {

            if (fileList.length > 0) {
                dispatch(uploadLogoAction({id: e.Id, file: newLogo})).unwrap().then((e) => {
                    navigate(`/loading/${e.Id}`);

                }).catch(({response}) => {
                })
            } else {
                navigate(`/loading/${e.Id}`);
            }
        }).catch(({response}) => {
        })

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("errorInfo:", errorInfo)
    };

    return (
        <Component>
            <StyledForm
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                requiredMark={false}
                colon={false}
                initialValues={
                    {
                        DefaultService: "RemoveBG",
                        ReducingMethod: "Cutoff",
                        OutlineColor: "#ffffff",
                        FillColor: "#ffffff",
                        ClosedGapColor: "#ffffff",
                        FillingClosedGaps: true,
                    }
                }
            >
                <FormRow>
                    <FormColumn>
                        <Form.Item
                            name="Name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Name!',
                                },
                            ]}
                        >
                            <Input placeholder={"Name"}/>
                        </Form.Item>

                        <Form.Item
                            label="Default service"
                            name="DefaultService"
                        >
                            <Select suffixIcon={<ChevronDownSvg/>}
                                    dropdownRender={(el) => {
                                        return <SelectWrapper><PointerCloudSvg/>{el}</SelectWrapper>
                                    }}
                                    dropdownStyle={StyleSelect}
                            >
                                <Option value="RemoveBG">RemoveBG</Option>
                                <Option value="ClippingMagic">ClippingMagic</Option>
                                <Option value="None">None</Option>

                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Reducing method"
                            name="ReducingMethod"
                            style={{display: 'none'}}
                        >
                            <Select suffixIcon={<ChevronDownSvg/>}
                                    dropdownRender={(el) => {
                                        return <SelectWrapper><PointerCloudSvg/>{el}</SelectWrapper>
                                    }}
                                    dropdownStyle={StyleSelect}
                            >
                                <Option value="Squeeze">Squeeze</Option>
                                <Option value="Cutoff">Cutoff</Option>
                            </Select>
                        </Form.Item>

                        <ConfigProvider
                            theme={{
                                components: {
                                    Switch: {
                                        handleBg: "#DB5C34",
                                        handleShadow: `calc(0px * ${scale}) calc(0px * ${scale}) calc(5px * ${scale}) rgba(0, 0, 0, 0.30)`,
                                        handleSize: 33 * scale,
                                        trackMinWidth: 105 * scale,
                                        trackHeight: 50 * scale,
                                        trackPadding: 6 * scale,
                                    },
                                },
                            }}
                        >
                            <StyledSwitchWrapper
                                label="Filling closed gaps"
                                name="FillingClosedGaps"
                                className={"height50"}
                            >
                                <StyledSwitch checkedChildren="ON" unCheckedChildren="OFF"/>
                            </StyledSwitchWrapper>
                        </ConfigProvider>

                        <Form.Item
                            label="Figures height mm"
                            name="FigureHeight"
                            initialValue={130}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Figures height mm',
                                },
                            ]}
                        >
                            <InputNumber min={90} max={250} placeholder={"mm"}/>
                        </Form.Item>

                        <Form.Item
                            label="Outline mm"
                            name="Outline"
                            initialValue={1}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Outline mm',
                                },
                            ]}
                        >
                            <InputNumber min={0} max={5} placeholder={"mm"}/>
                        </Form.Item>

                    </FormColumn>


                    <FormColumn>
                        <Form.Item
                            label="Logo"
                            name="Logo"
                            valuePropName="file"
                            getValueFromEvent={normFile}
                        >
                            <Upload customRequest={({onSuccess}) => {
                                onSuccess && onSuccess("окей")
                            }}
                                    onChange={({fileList: newFileList}) => {
                                        setFileList(newFileList);
                                    }}
                                    onRemove={() => {
                                        setFileList([]);
                                    }}
                                    maxCount={1}
                                    accept="image/png, image/jpeg"
                                    listType="picture"
                            >
                                {
                                    fileList.length === 0 &&
                                    <button style={{border: 0, background: 'none'}} type="button">
                                        <UploadText>
                                            <PlusInCircleSvg/> <span>Add file</span>
                                        </UploadText>
                                    </button>
                                }
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="DPI"
                            name="DPI"
                            initialValue={200}
                            style={{display: 'none'}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input DPI',
                                },
                            ]}
                        >
                            <InputNumber min={72} max={300} placeholder={"DPI"}/>
                        </Form.Item>

                        <Form.Item
                            label="Logo Height"
                            name="LogoHeight"
                            initialValue={12}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input logo height',
                                },
                            ]}
                        >
                            <InputNumber min={10} max={50} placeholder={"logo height"}/>
                        </Form.Item>

                        <Form.Item
                            label="Logo Indent"
                            name="LogoIndent"
                            initialValue={1}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input logo indent',
                                },
                            ]}
                        >
                            <InputNumber min={1} max={10} placeholder={"logo indent"}/>
                        </Form.Item>

                        <Form.Item
                            label="Max Logo Width"
                            name="LogoMaxWidth"
                            initialValue={45}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input max logo width',
                                },
                            ]}
                        >
                            <InputNumber min={30} max={70} placeholder={"max logo width"}/>
                        </Form.Item>

                        <FormItemColorPiker label="Base color" name="ClosedGapColor" form={form}/>
                    </FormColumn>
                </FormRow>
                <FormRow>

                    <FormColumn>
                        <FormItemColorPiker label="Outline color" name="OutlineColor" form={form}/>
                    </FormColumn>

                    <FormColumn>
                        <FormItemColorPiker label="Fill color" name="FillColor" form={form}/>
                    </FormColumn>
                </FormRow>

                <ButtonSaveWrapper>
                    <ButtonSave>
                        <Button type="primary" htmlType="submit">
                            Create
                        </Button>
                    </ButtonSave>
                </ButtonSaveWrapper>
            </StyledForm>
        </Component>
    );
}

export default SettingsForm;