import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import PlusCircleImg from "../images/plusCircle.png";
import {Button, Dropdown, Form, MenuProps, Modal, Select, Space, Spin, Upload} from "antd";
import {Option} from "antd/es/mentions";
import PlusSvg from "../images/plusSvg";
import MinusSvg from "../images/minusSvg";
import ChevronDownSvg from "../images/chevronDownSvg";
import PointerCloudSvg from "../images/PointerCloudSvg";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import {
    addPhotoAction,
    deleteFigureAction,
    editCountsAction,
    getFigureTabAction,
    getProcessingSettingsAction,
    getTabNamesAction,
    moveFigureAction,
    setLoadingAction
} from "../redux/pageSlice";
import {useNavigate, useParams} from "react-router-dom";
import {FigureType} from "../utils/types";
import MiniTrashSvg from "../images/miniTrashSvg";
import DownloadSvg from "../images/downloadSvg";
import {api, scale} from "../config";
import CornerUpRightSvg from "../images/cornerUpRightSvg";

const Component = styled.div`
  width: 100%;
`;

const ActionPhoto = styled.div<{ $disabled?: boolean }>`
  width: calc(33px * ${scale});
  height: calc(33px * ${scale});
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(50px * ${scale});

  background: ${props => !props.$disabled ? "#E1B55A" : "#dcceb1"};

  cursor: ${props => !props.$disabled ? "pointer" : "not-allowed"};

  svg {

    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
  }

  &:hover {

    background: ${props => !props.$disabled ? "#DB5C34" : "#dcceb1"};

  }
`;

const ActionsUnderPhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(15px * ${scale});
  margin-top: calc(20px * ${scale});
  user-select: none;

  position: relative;
`;


const ImageWrapper = styled.div<{ $isCountZero: boolean }>`
  width: 100%;
  height: calc(335px * ${scale});
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECE8E1;

  transition: .3s;
  opacity: ${props => props.$isCountZero ? ".5" : "1"};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ImageBlock = styled.div`
  max-width: calc(280px * ${scale});
  width: 100%;
`;

const SpinForGrid = styled(Spin)`
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);;
`;

const Grid = styled.div<{ $isLoading: boolean, $isLoadingGetProcessing: boolean }>`
  width: 100%;
  min-height: 300px;
  margin-top: calc(30px * ${scale});

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: calc(50px * ${scale}) calc(20px * ${scale});

  position: relative;

  & > ${ImageBlock} {
    ${props => props.$isLoadingGetProcessing ? "opacity: 0;" : "opacity: 1;"}
  }

  & ${ActionPhoto} {
    ${props => props.$isLoading ? "pointer-events: none;" : ""};
    ${props => props.$isLoading ? "background: #dcceb1;" : ""};

    & svg path {
      ${props => props.$isLoading ? "stroke: #595959;" : ""};
    }
  }
`;


const Number = styled.div`
  color: #1C1C1C;
  font-family: Roboto, serif;
  font-size: calc(18px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: calc(38px * ${scale});
`;


const AddButton = styled.div`
  cursor: pointer;
  user-select: none;

  img {
    width: calc(78px * ${scale});
    height: calc(75px * ${scale});
  }

  &:hover {
    opacity: .8;
  }
`;


const GenerateButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: calc(70px * ${scale});
`;

const GenerateButton = styled.div<{ $disabled: boolean }>`
  width: calc(260px * ${scale});
  height: calc(60px * ${scale});

  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(10px * ${scale});

  border-radius: calc(20px * ${scale});

  background: ${props => !props.$disabled ? "#E1B55A;" : "#dcceb1"};

  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  cursor: ${props => !props.$disabled ? "pointer;" : "not-allowed"};

  &:hover {
    background: ${props => !props.$disabled ? "#DB5C34;" : "#dcceb1"};
  }
`;

const Folders = styled.div`
  border-radius: calc(10px * ${scale});
  background: #F3E0BE;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
  min-height: calc(60px * ${scale});
  margin-top: calc(8px * ${scale});
  gap: calc(10px * ${scale});
`;

const Folder = styled.div<{ $selected?: boolean }>`
  border-radius: calc(10px * ${scale});
  background: #E1B55A;

  width: calc(135px * ${scale});
  height: calc(60px * ${scale});

  display: flex;
  align-items: center;
  justify-content: center;

  border: solid calc(1px * ${scale}) ${props => props.$selected ? "#000" : "#0000"};

  color: #1C1C1C;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  cursor: pointer;

  &:hover {
    background: #DB5C34;
  }
`;

const AddFolderButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: calc(10px * ${scale});
  height: calc(60px * ${scale});

  cursor: pointer;

  svg {
    width: calc(32px * ${scale});
    height: calc(40px * ${scale});
    padding-left: calc(10px * ${scale});
    box-sizing: content-box;
    border-left: calc(2px * ${scale}) solid #FAF3E9;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: calc(55px * ${scale}) calc(55px * ${scale}) calc(30px * ${scale});

  color: #FAF3E9;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-weight: 500;
  line-height: 140%;

  & > svg > path {
    stroke: #FAF3E9;
  }

  & > b {
    margin: calc(30px * ${scale}) 0 calc(20px * ${scale});

    font-family: Krona One, serif;
    font-size: calc(18px * ${scale});
    font-weight: 400;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;

  .ant-form-item {
    height: calc(96px * ${scale});
    margin: 0;

    .ant-row {
      & .ant-form-item-control {
        height: calc(60px * ${scale});
        flex: 0 1 auto;

        & .ant-form-item-control-input {
          height: 100%;

          & .ant-form-item-control-input-content {
            height: 100%;

            & .ant-select {
              height: 100%;

              .ant-select-arrow {
                svg {

                  width: calc(24px * ${scale});
                  height: calc(24px * ${scale});
                }

                inset-inline-end: calc(24px * ${scale});
              }

            }

          }
        }


        & .ant-select-selector {
          border-radius: calc(20px * ${scale});
          border: calc(2px * ${scale}) solid #1C1C1C !important;

          width: 100%;

          padding: calc(19px * ${scale}) calc(24px * ${scale});
          color: #1C1C1C;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
`;


const FormUploadsContainer = styled.div`
  width: 100%;
  margin-bottom: calc(50px * ${scale});

  position: relative;

  .ant-upload-list {
    display: none;
  }

  & > span {
    position: absolute;
    top: calc(8px * ${scale});
    left: calc(10px * ${scale});

    color: #A5A5A5;
    text-align: center;
    font-family: "Krona One", serif;
    font-size: calc(14px * ${scale});
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  & .ant-form-item {
    height: auto;

    & .ant-row {
      & .ant-col {
        max-width: 100%;
        height: auto;

        .ant-form-item-control-input-content {
          .ant-upload-wrapper {
            display: flex;
            flex-direction: column-reverse;
            gap: calc(20px * ${scale});

            .ant-upload-select {
              order: 10000;

              height: calc(218px * ${scale});
              width: 100%;

              border-radius: calc(10px * ${scale});
              cursor: pointer;
              border: calc(3px * ${scale}) dashed #A5A5A5;

              .ant-upload {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;

                button {

                  cursor: pointer;
                }
              }

              &:hover {
                border: calc(3px * ${scale}) dashed #DB5C34;
              }
            }
          }
        }
      }
    }
  }
`;


const UploadText = styled.div`
  user-select: none;
  pointer-events: none;

  color: #A5A5A5;
  text-align: center;
  font-family: Krona One, serif;
  font-size: calc(24px * ${scale}) !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > span {
    color: #DB5C34;
    font-family: Krona One, serif;
    font-size: calc(24px * ${scale});
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;


const SelectWrapper = styled.div`

  border-radius: calc(20px * ${scale});
  background: #1C1C1C;
  padding: calc(20px * ${scale}) 0;
  margin-top: calc(25px * ${scale});
  position: relative;

  & > svg {
    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
    bottom: calc(100% - 8px * ${scale});

    position: absolute;
    right: calc(19px * ${scale});

    path {
      fill: #1C1C1C;
    }
  }

  .ant-select-item {

    padding: calc(10px * ${scale}) calc(24px * ${scale});
    border-radius: 0;

    &.ant-select-item-option-active {
      background: #fff1;
    }

    &.ant-select-item-option-selected {
      background: #fff2;
    }

    .ant-select-item-option-content {
      color: #A5A5A5;
      font-family: Roboto, serif;
      font-size: calc(16px * ${scale});
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
`;


const DropDownWrapper = styled.div`
  width: calc(280px * ${scale});

  border-radius: calc(20px * ${scale});
  background: #1C1C1C;
  margin-top: calc(15px * ${scale});
  position: relative;
  padding: calc(5px * ${scale}) 0;

  margin-right: calc(-17px * ${scale});

  & > svg {
    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
    bottom: calc(100% - 8px * ${scale});

    position: absolute;
    right: calc(19px * ${scale});

    path {
      fill: #1C1C1C;
    }
  }

  .ant-dropdown-menu {
    background: #0000;
    padding: 0;

    li.ant-dropdown-menu-item {

      width: 100%;
      height: calc(52px * ${scale});
      border-radius: 0;
      padding: 0;

      &.ant-dropdown-menu-item-active {
        background-color: #E1B55A4D !important;
      }

      .ant-dropdown-menu-title-content {
        color: #FAF3E9;

        font-family: Roboto, serif;
        font-size: calc(16px * ${scale});
        font-style: normal;
        font-weight: 500;
        line-height: 140%;

        height: 100%;

        & > span {
          padding: 0 calc(27px * ${scale});
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;


const Buttons = styled.div`
  display: flex;
  gap: calc(20px * ${scale});
  justify-content: center;
  margin-top: calc(34px * ${scale});
`;

const ButtonSave = styled(Form.Item)`
  width: calc(245px * ${scale});
  height: calc(60px * ${scale});

  & .ant-col {
    margin: 0;
    max-width: 100%;
  }

  & button {
    width: 100%;
    height: calc(60px * ${scale});

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    color: #000 !important;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    &:hover {
      background: #DB5C34 !important;
    }
  }
`;

const ButtonCancelModal = styled.div`
  width: calc(245px * ${scale});
  height: calc(60px * ${scale});

  border-radius: calc(20px * ${scale});
  color: #FAF3E9;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  cursor: pointer;

  border: calc(3px * ${scale}) solid #E1B55A;
  background: #0000;

  &:hover {
    border: calc(3px * ${scale}) solid #DB5C34;
  }
`;


function ImgWithLoader(props: any) {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {loading ? <Spin/> : ""}
            <img
                style={{display: loading ? "none": "block"}}
                {...props}
                onLoad={handleImageLoad}
            />
        </>
    )
}

function TableFile(value: FigureType,
                   index: number,
                   tab: string,
                   tabs: string[],
                   moveFigure: (idFigure: number | string, targetTabMove: string) => void,
                   download: (idFigure: number) => void,
                   deleteFigure: (idFigure: number) => void,
                   changeNumberHandler: (id: number, count: number) => void) {


    const items: MenuProps['items'] = tabs.map((tab, index) => {
        return {
            label: <span onClick={() => {
                moveFigure(value.Id, tab)
            }}>{tab}</span>,
            key: index
        }
    })

    return (
        <ImageBlock key={index + tab}>
            <Zoom>
                {/*{loadingImg ? "123": ""} onLoad={handleImageLoad}*/}
                <ImageWrapper $isCountZero={value.Count === 0}>
                    <ImgWithLoader src={"/" + value.Path} alt="figure"/>
                </ImageWrapper>
            </Zoom>
            <ActionsUnderPhoto>
                <ActionPhoto onClick={() => changeNumberHandler(value.Id, value.Count - 1)}><MinusSvg/></ActionPhoto>
                <Number>{value.Count}</Number>
                <ActionPhoto onClick={() => changeNumberHandler(value.Id, value.Count + 1)}><PlusSvg/></ActionPhoto>
                <ActionPhoto onClick={() => download(value.Id)}><DownloadSvg/></ActionPhoto>
                <ActionPhoto onClick={() => {
                    deleteFigure(value.Id)
                }}> <MiniTrashSvg/></ActionPhoto>


                <Dropdown menu={{items}} trigger={['click']}
                          dropdownRender={(el) => {
                              return <DropDownWrapper><PointerCloudSvg/>{el}</DropDownWrapper>
                          }}
                          placement="bottomRight"
                          disabled={tabs.length <= 0}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <ActionPhoto $disabled={tabs.length <= 0}>
                                <CornerUpRightSvg/>
                            </ActionPhoto>
                        </Space>
                    </a>
                </Dropdown>
            </ActionsUnderPhoto>
        </ImageBlock>
    )
}

const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const StyleSelect = {background: "#0000", boxShadow: "0 0 0 0", padding: 0}

function PhotoEditor() {

    const tabs = useAppSelector(state => state.page.tabs)
    const figures = useAppSelector(state => state.page.figures)
    const processingSettings = useAppSelector(state => state.page.processingSettings)
    const loading = useAppSelector(state => state.page.loading)

    const dispatch = useAppDispatch()

    const navigate = useNavigate();
    const {id} = useParams()
    const {tab} = useParams()

    const [isLoadingNumber, setIsLoadingNumber] = useState<boolean>(false);
    const [isLoadingGetProcessing, setIsLoadingGetProcessing] = useState<boolean>(true);

    const [isLoadingGetFigures, setIsLoadingGetFigures] = useState<boolean>(true);

    const [fakeFolder, setFakeFolder] = useState<string>("");

    // Modal
    const [openCreate, setOpenCreate] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const [form] = Form.useForm()

    useEffect(() => {
        getTabs()

        if (id) {
            dispatch(getProcessingSettingsAction(id))
        }
    }, [id])

    useEffect(() => {

        if (id !== undefined) {
            setIsLoadingGetFigures(true)

            dispatch(getFigureTabAction({id: id, tab: tab + ""})).unwrap().then(() => {
                setIsLoadingGetFigures(false)
            }).catch(() => {
                setIsLoadingGetFigures(false)
            })
        }
    }, [id, tab])

    const getTabs = () => {
        if (id !== undefined) {

            dispatch(setLoadingAction(true))
            dispatch(getTabNamesAction(id)).unwrap().then(() => {

                dispatch(setLoadingAction(false))
                setIsLoadingGetProcessing(false)
            })
        }
    }

    const addFolder = () => {
        if (!fakeFolder) {
            let newFolder = "Tab";
            let count = 1;
            while (tabs.includes(newFolder + "" + count)) {
                count++;
            }
            newFolder = newFolder + "" + count;
            setFakeFolder(newFolder)
        }
    }

    const removeFakeFolder = (tabName: string) => {
        if (fakeFolder === tabName) {
            getTabs()
            setFakeFolder("")
        }
    }

    const clickFolderHandler = (value: any) => {
        navigate(`/preview/${id}/${value}`);
    }

    const GenerateHandler = () => {
        if (!(figures.length <= 0)) {
            navigate(`/generated/${id}/${tab}`);
        }
    };

    const deleteFigure = (idFigure: number) => {
        setIsLoadingGetProcessing(true)
        dispatch(deleteFigureAction({idProcessing: id + "", tab: tab + "", idFigure: idFigure})).unwrap().then(() => {
            setIsLoadingGetProcessing(false)
        })
    }

    const changeNumberHandler = (idFigure: number, count: number) => {
        let ignore = false
        if (count < 0) count = 0;

        figures.map((value) => {
            let newCount = value?.Count
            if (idFigure === value.Id && newCount === count) {
                ignore = true;
            }
        })

        if (!ignore && id !== undefined) {
            setIsLoadingNumber(true)
            dispatch(editCountsAction({
                Id: id,
                Tab: tab + "",
                Figures: [...figures.filter(el => el.Id !== idFigure), {Id: idFigure, Count: count}]
            })).unwrap().finally(() => {
                setIsLoadingNumber(false)
            })
        }
    }


    const showUploadModal = () => {
        setOpenCreate(true)
    };

    const handleUploadCancel = () => {
        setOpenCreate(false)
        form.resetFields()
        setFiles([])
    };

    const onFinishUpload = (values: any) => {

        if (id && tab) {
            dispatch(addPhotoAction({
                Id: id,
                Files: [...values.Files],
                service: values.service,
                TabName: tab

            })).unwrap().then(() => {
                setFiles([])
                form.resetFields()
                setOpenCreate(false)
                removeFakeFolder(tab)
                getTabs()
            }).catch(() => {

            })
        }
    };

    const onFinishFailedCreate = (errorInfo: any) => {
        console.log("errorInfo", errorInfo)

    };

    const onChangeUpload = ({fileList}: { fileList: any }) => {
        setFiles(fileList)
    };

    const download = (idFigure: number | string) => {
        const FileLink = document.createElement('a');
        FileLink.href = `${api}/processings/${id}/folders/${tab}/${idFigure}/download`;
        FileLink.download = 'my-filename.ext';
        document.body.appendChild(FileLink);
        FileLink.click();
        FileLink.remove();
    }

    const moveFigure = (idFigure: number | string, targetTabMove: string) => {
        const args = {
            idProcessing: id + "",
            tab: tab + "",
            idFigure, targetTabMove
        }
        setIsLoadingGetProcessing(true)
        dispatch(moveFigureAction(args)).unwrap().then(() => {
            setIsLoadingGetProcessing(false)
            removeFakeFolder(targetTabMove)
        })
    }

    return (
        <Component>
            <Modal
                open={openCreate}
                classNames={{footer: "modalActionFooter"}}
                onCancel={handleUploadCancel}
                centered
                closeIcon={null}
                styles={{
                    mask: {background: "#0003"},
                    content: {
                        background: "#1C1C1C",
                        borderRadius: `calc(30px * ${scale})`,
                        padding: "0",
                        display: "flex",
                        justifyContent: "center"
                    },
                    footer: {display: "none"}
                }}
                width={`calc(780px * ${scale})`}
            >
                <Spin spinning={loading}>
                    <ModalContent>
                        <StyledForm

                            form={form}
                            onFinish={onFinishUpload}
                            onFinishFailed={onFinishFailedCreate}
                            autoComplete="off"
                            initialValues={{
                                service: id ? processingSettings[id]?.DefaultService : "None",
                                Files: []
                            }}
                        >

                            <FormUploadsContainer>
                                <Form.Item name="Files" valuePropName="fileList" getValueFromEvent={normFile}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: 'Please upload photo!',
                                               },
                                           ]}
                                >
                                    <Upload customRequest={({onSuccess}) => {
                                        onSuccess && onSuccess("окей")
                                    }}
                                            fileList={files}
                                            onChange={onChangeUpload}
                                            multiple
                                            accept="image/png, image/jpeg" listType="picture">
                                        <button style={{border: 0, background: 'none'}} type="button">
                                            <UploadText>
                                                Drag a file here or <span> browse </span> for a file to upload
                                            </UploadText>
                                        </button>
                                    </Upload>
                                </Form.Item>
                                {files.length !== 0 && <span>Loaded files: {files.length}</span>}
                            </FormUploadsContainer>
                            <Form.Item
                                name="service"
                            >
                                <Select suffixIcon={<ChevronDownSvg/>}
                                        dropdownRender={(el) => {
                                            return <SelectWrapper><PointerCloudSvg/>{el}</SelectWrapper>
                                        }}
                                        dropdownStyle={StyleSelect}
                                >
                                    <Option value="RemoveBG">RemoveBG</Option>
                                    <Option value="ClippingMagic">ClippingMagic</Option>
                                    <Option value="None">None</Option>
                                </Select>
                            </Form.Item>


                            <Buttons>
                                <ButtonCancelModal onClick={handleUploadCancel}>
                                    Cancel
                                </ButtonCancelModal>
                                <ButtonSave
                                    wrapperCol={{
                                        offset: 8 * scale,
                                        span: 16 * scale,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        Confirm
                                    </Button>
                                </ButtonSave>
                            </Buttons>
                        </StyledForm>
                    </ModalContent>
                </Spin>
            </Modal>

            <TopWrapper>
                <Folders>
                    {tabs.slice().sort().map((value, key) => {
                        return <Folder $selected={tab === value} onClick={() => clickFolderHandler(value)}
                                       key={key}>{value}</Folder>
                    })}

                    {
                        fakeFolder &&
                        <Folder onClick={() => clickFolderHandler(fakeFolder)} key={"-1"}>{fakeFolder}</Folder>
                    }
                    <AddFolderButton onClick={addFolder}>
                        <PlusSvg/>
                    </AddFolderButton>
                </Folders>

                <AddButton onClick={showUploadModal}>
                    <img src={PlusCircleImg} alt="PlusCirclePng"/>
                </AddButton>
            </TopWrapper>

            <Grid $isLoadingGetProcessing={isLoadingGetProcessing} $isLoading={isLoadingNumber}>
                {isLoadingGetFigures ? <SpinForGrid></SpinForGrid> :
                    figures.map((value, index) => {
                        return TableFile(
                            value, index, tab + "",
                            [...tabs, fakeFolder].filter(el => el !== tab && el !== ""),
                            moveFigure, download, deleteFigure, changeNumberHandler)
                    })

                }
                {
                    !figures.length && "Folder is empty"
                }
            </Grid>

            <GenerateButtonWrapper>
                <GenerateButton $disabled={figures.length <= 0 || isLoadingGetFigures}
                                onClick={GenerateHandler}>Generate</GenerateButton>
            </GenerateButtonWrapper>
        </Component>
    );
}

export default PhotoEditor;