import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Button,
    Checkbox,
    ColorPicker, ColorPickerProps,
    ConfigProvider, Dropdown,
    Form, GetProp,
    InputNumber, MenuProps,
    message,
    Radio,
    Select, Space,
    Spin,
    Switch
} from "antd";
import {Option} from "antd/es/mentions";
import {Link, useParams} from "react-router-dom";
import {
    generateAction,
    getPreviewAction,
    getProcessingSettingsAction,
    getTabNamesAction, moveFigureAction, moveFiguresFromPageAction,
    setLoadingAction
} from "../redux/pageSlice";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {api, scale} from "../config";
import Input from 'antd/es/input/Input';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {FormSettingsType, SettingsType, SizePapersType} from "../utils/types";

import MarginSquareSvg from "../images/marginSquareSvg";
import MarginLeftRightSvg from "../images/MarginLeftRightSvg";
import Arrow1Svg from "../images/Arrow1Svg";
import ChevronDownSvg from "../images/chevronDownSvg";
import PointerCloudSvg from "../images/PointerCloudSvg";
import MarginLeftSvg from "../images/MarginLeftSvg";
import ArrowExitSvg from "../images/ArrowExitSvg";
import {Color} from 'antd/es/color-picker';
import {CheckOutlined} from '@ant-design/icons';
import CheckedSvg from "../images/CheckedSvg";
import CornerUpRightSvg from "../images/cornerUpRightSvg";

const Component = styled.div`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  margin-top: 0;

  display: flex;
  justify-content: space-between;
`;

const TitlesBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: #1C1C1C;
  font-family: Krona One, serif;
  font-size: calc(14px * ${scale});
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
`;


const ImageWrapper = styled.div`
  width: 100%;
  height: calc(724px * ${scale});
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECE8E1;

  img {
    max-width: 100%;
    max-height: calc(724px * ${scale});
  }
`;

const SettingsBlock = styled.div`
  max-width: calc(480px * ${scale});
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: calc(84px * ${scale});
`;

const Action = styled.div`
  background: #E1B55A;
  width: calc(32px * ${scale});
  height: calc(33px * ${scale});
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(50px * ${scale});

  cursor: pointer;

  &:hover {
    background: #DB5C34;
  }

  svg {
    height: calc(14px * ${scale});
  }
`;

const Actions = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(20px * ${scale});
  margin-top: calc(30px * ${scale});

  font-family: Roboto, serif;
  font-size: calc(18px * ${scale});
  font-weight: 500;
  line-height: calc(25px * ${scale});
  letter-spacing: 0;
  text-align: center;

  user-select: none;

  pointer-events: ${props => props.$disabled && "none"};

  & ${Action} {
    opacity: ${props => props.$disabled && ".7"};
  }
`;

const SubGroupActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(20px * ${scale});

  font-family: Roboto, serif;
  font-size: calc(18px * ${scale});
  font-weight: 500;
  line-height: calc(25px * ${scale});
  letter-spacing: 0;
  text-align: center;

  user-select: none;
`;


const DropDownWrapper = styled.div`
  width: calc(280px * ${scale});

  border-radius: calc(20px * ${scale});
  background: #1C1C1C;
  margin-top: calc(15px * ${scale});
  position: relative;
  padding: calc(5px * ${scale}) 0;

  margin-right: calc(-17px * ${scale});

  & > svg {
    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
    bottom: calc(100% - 8px * ${scale});

    position: absolute;
    right: calc(19px * ${scale});

    path {
      fill: #1C1C1C;
    }
  }

  .ant-dropdown-menu {
    background: #0000;
    padding: 0;

    li.ant-dropdown-menu-item {

      width: 100%;
      height: calc(52px * ${scale});
      border-radius: 0;
      padding: 0;

      &.ant-dropdown-menu-item-active {
        background-color: #E1B55A4D !important;
      }

      .ant-dropdown-menu-title-content {
        color: #FAF3E9;

        font-family: Roboto, serif;
        font-size: calc(16px * ${scale});
        font-style: normal;
        font-weight: 500;
        line-height: 140%;

        height: 100%;

        & > span {
          padding: 0 calc(27px * ${scale});
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;


const SettingsField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(39px * ${scale});
`;


const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div:not(${SettingsField}) .ant-row {

    & .ant-input-number {
      & input {
        padding: calc(19px * ${scale}) calc(40px * ${scale}) calc(19px * ${scale}) calc(24px * ${scale});
      }
    }
  }

  .ant-form-item {
    height: calc(96px * ${scale});
    margin: 0;

    .ant-row {

      flex-flow: row;
      justify-content: space-between;

      .ant-form-item-label {
        display: flex;
        align-items: center;

        & label {
          color: #787878;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          text-transform: uppercase;
        }
      }

      & .ant-form-item-control {
        width: calc(300px * ${scale});
        height: calc(60px * ${scale});
        flex: 0 1 auto;

        & .ant-input-number {
          border: 0;
          width: 100%;
          height: 100%;

          background: #0000;

          input {
            background: #FAF3E9;
          }

          & .ant-input-number-handler-wrap {
            opacity: 1;
            right: calc(19px * ${scale});
            border: 0;
            background: #0000;
            display: flex;
            justify-content: center;

            span {
              border: 0;
              height: calc(24px * ${scale});
              flex: 0 1 auto;

              svg {
                width: calc(24px * ${scale});
                height: calc(24px * ${scale});
              }
            }
          }

        }

        & input {
          height: 100%;
          border-radius: calc(20px * ${scale});
          border: calc(2px * ${scale}) solid #1C1C1C;
          padding: calc(19px * ${scale}) calc(24px * ${scale});

          color: #1C1C1C;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }


        & .ant-form-item-control-input {
          height: 100%;
          margin-left: auto;

          & .ant-form-item-control-input-content {
            height: 100%;

            & .ant-select {
              height: 100%;

              .ant-select-arrow {
                svg {
                  width: calc(24px * ${scale});
                  height: calc(24px * ${scale});
                }

                inset-inline-end: calc(24px * ${scale});
              }

            }

          }
        }

        & .ant-select-selector {
          border-radius: calc(20px * ${scale});
          border: calc(2px * ${scale}) solid #1C1C1C !important;

          width: 100%;

          padding: calc(19px * ${scale}) calc(24px * ${scale});
          color: #1C1C1C;
          font-family: Roboto, serif;
          font-size: calc(16px * ${scale});
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }

  .height50 {
    .ant-row {
      & .ant-form-item-control {
        height: calc(50px * ${scale});
      }
    }

  }


  .short .ant-row .ant-form-item-control .ant-form-item-control-input {
    width: calc(105px * ${scale});

    & .ant-select-selector {
      width: calc(105px * ${scale});
    }
  }

  .slightlyLess .ant-row .ant-form-item-control {
    width: calc(430px * ${scale});

    & .ant-select-selector {
      width: calc(430px * ${scale});
    }
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-17sses9).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {

    border: calc(2px * ${scale}) solid #DB5C34;
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-checked .ant-checkbox-inner {

    border: calc(2px * ${scale}) solid #DB5C34;
  }

  & :where(.css-dev-only-do-not-override-17sses9).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {

    background: #DB5C34;
  }

  .checkbox .ant-row .ant-form-item-control {
    width: auto;

    label {
      width: calc(34px * ${scale});
      height: calc(34px * ${scale});

      & > span {
        width: 100%;
        height: 100%;

        &.ant-checkbox-checked > span {
          background: #DB5C34;
          border-color: #0000;

          &:after {
            transform: rotate(45deg) scale(1.7) translate(-20%, -60%);
          }
        }

        & > span {
          width: 100%;
          height: 100%;
          border-radius: calc(10px * ${scale});
          border-color: #1C1C1C;
          background: #FAF3E9;

          &:after {
            transform: rotate(45deg) scale(1.7) translate(-20%, -60%);
          }
        }
      }
    }
  }

  .ant-upload-wrapper {
    display: block;
    width: 100%;

    .ant-upload {
      display: block;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  .ant-upload-list {
    display: none;

    .ant-upload-list-item {
      margin: 0;
      height: calc(60px * ${scale}) !important;
    }
  }

  .ant-form-item-has-success {
    .ant-upload.ant-upload-select {
      display: none;
    }

    .ant-upload-list {
      display: block;
    }
  }
`;

const StyledSwitchWrapper = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledSwitch = styled(Switch)`

  border: calc(2px * ${scale}) solid #1C1C1C;
  background: #0000 !important;

  &:disabled {
    opacity: 1;
  }

  & .ant-wave {
    display: none;
  }

  .ant-switch-inner {
    & > span {
      height: 100%;

      color: #1C1C1C !important;
      font-family: Roboto, serif !important;
      font-size: calc(14px * ${scale}) !important;
      font-weight: 500 !important;
    }
  }

  .ant-switch-inner-checked {
    padding-left: calc(11px * ${scale});
    display: flex !important;
    align-items: center;
    justify-content: flex-start !important;
  }

  .ant-switch-inner-unchecked {
    padding-top: calc(5px * ${scale});
    padding-right: calc(11px * ${scale});
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
  }

`;


const SelectWrapper = styled.div`

  border-radius: calc(20px * ${scale});
  background: #1C1C1C;
  padding: calc(20px * ${scale}) 0;
  margin-top: calc(25px * ${scale});
  position: relative;

  & > svg {
    width: calc(24px * ${scale});
    height: calc(24px * ${scale});
    bottom: calc(100% - 8px * ${scale});

    position: absolute;
    right: calc(19px * ${scale});

    path {
      fill: #1C1C1C;
    }
  }

  .ant-select-item {

    padding: calc(10px * ${scale}) calc(24px * ${scale});
    border-radius: 0;

    &.ant-select-item-option-active {
      background: #fff1;
    }

    &.ant-select-item-option-selected {
      background: #fff2;
    }

    .ant-select-item-option-content {
      color: #A5A5A5;
      font-family: Roboto, serif;
      font-size: calc(16px * ${scale});
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
`;

const SettingsFieldLabel = styled.div`
  color: #787878;
  display: flex;
  align-items: center;
  height: calc(60px * ${scale});
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
`;

const MarginFiled = styled.div`
  width: calc(300px * ${scale});
  display: flex;
  justify-content: space-between;

  .ant-form-item {
    height: calc(60px * ${scale});

    .ant-row .ant-col {
      .ant-input-number-group-addon {
        padding: 0;
        border: 0 solid #0000;
        border-radius: calc(12px * ${scale});

        svg {
          width: calc(26px * ${scale});
          height: calc(26px * ${scale});
        }
      }

      .ant-input-number {
        background: #0000;
        border: 0;
        width: 100%;
        height: 100%;

        & .ant-input-number-handler-wrap {
          display: none;
        }
      }

      .ant-input-number-wrapper {
        width: calc(119px * ${scale});
        height: calc(49px * ${scale});
        border: 0 solid #A5A5A5;
        border-radius: calc(10px * ${scale});
        padding: 0 0 0 calc(15px * ${scale});
      }

      input {
        display: flex;
        width: 100%;
        height: calc(49px * ${scale});
        justify-content: center;
        align-items: center;
        border-radius: 0;
        padding: 0 0 0 calc(10px * ${scale});
        border: 0 solid #0000;
        background: #0000;
      }

      .ant-input-number-focused {
        .ant-input-number-wrapper {
          border: calc(2px * ${scale}) solid #A5A5A5;
        }
      }
    }
  }

  & > svg {
    cursor: pointer;
    margin-top: calc(9px * ${scale});
    width: calc(34px * ${scale});
    height: calc(34px * ${scale});
  }
`;

const CustomSizeFiled = styled(MarginFiled)`
  gap: calc(10px * ${scale});

  .ant-form-item {
    .ant-row .ant-col {
      input {

        height: calc(60px * ${scale});
        border: calc(2px * ${scale}) solid #1C1C1C !important;
        border-radius: calc(20px * ${scale});
      }
    }
  }

`;

const ColorPikerRadio = styled(Radio)<{ $color: string }>`
  background: ${props => props.$color};
  width: calc(33px * ${scale});
  height: calc(33px * ${scale});
  border-radius: calc(100px * ${scale});
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: ${props => props.$color === "#ffffff" || props.$color === "#ffffff00" ? `calc(1px * ${scale}) solid #000` : ""};

  & .ant-radio {
    display: none;
  }

  &.ant-radio-wrapper-checked {

    & + svg {
      display: block;
    }
  }
`;

const ColorWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    height: calc(20px * ${scale});
    width: calc(20px * ${scale});
    pointer-events: none;
    display: none;

    background: #0005;
    border-radius: 100%;
    padding: 2px;
  }
`;

const StyledColorPicker = styled(ColorPicker)<{ $isColorPickerSelected: boolean }>`
  width: calc(33px * ${scale});
  height: calc(33px * ${scale});
  min-width: auto;
  border: 0;
  background: #0000;
  box-shadow: 0 0 0 0 #0000;
  border-radius: 0;
  padding: 0;

  & > .ant-color-picker-color-block,
  & > .ant-color-picker-clear {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 #0000;
    border-radius: calc(100px * ${scale});
    border: 0;
  }

  & > .ant-color-picker-clear, & > .ant-color-picker-color-block > .ant-color-picker-color-block-inner {
    background: conic-gradient(#ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8a2be2, #ff0000) !important;
    border: 0 solid #0000;
  }

  & > .ant-color-picker-clear:after {
    display: none;
  }
`;


const ColorPikerFormItem = styled(Form.Item)`

  &.ant-form-item {
    height: calc(515px * ${scale});
    padding-top: calc(35px * ${scale});
    border-top: calc(1px * ${scale}) solid rgba(165, 165, 165, 0.20);

    & .ant-radio-group {
      display: grid;
      grid-template-columns: repeat(6, max-content);
      gap: calc(16px * ${scale});
    }
  }
`;


const MarginSettings = styled.div`
  width: calc(235px * ${scale});
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: calc(20px * ${scale});
  margin-top: calc(20px * ${scale});

  button {
    color: #000;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    display: flex;
    width: calc(213px * ${scale});
    height: calc(60px * ${scale});
    padding: calc(18px * ${scale}) calc(80px * ${scale});
    justify-content: center;
    align-items: center;
    margin-top: 0;
    border: 0;

    border-radius: calc(20px * ${scale});
    background: #E1B55A !important;

    &:not(:disabled):hover {
      color: #000 !important;
      background: #DB5C34 !important;
    }
  }
`;

const ImageBlock = styled.div`
  max-width: calc(580px * ${scale});
  width: 100%;
`;

const ExitButton = styled(Link)<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(65px * ${scale});
  height: calc(62.5px * ${scale});
  border: solid calc(3px * ${scale}) #000;
  border-radius: calc(100px * ${scale});
  margin: calc(6.5px * ${scale}) calc(6.5px * ${scale}) calc(34px * ${scale});

  pointer-events: ${props => props.$disabled && "none"};
  opacity: ${props => props.$disabled && ".7"};

  svg {
    width: calc(46px * ${scale});
    height: calc(48px * ${scale});
  }
`;

const StyleSelect = {background: "#0000", boxShadow: "0 0 0 0", padding: 0}

const colors = [
    "#ffffff00", "#ffffff", "#000000FF", "#FF8A00FF", "#9BAECEFF",
]

const initialFormValues: FormSettingsType = {
    ReducingMethod: "Cutoff",
    Copies: 1,
    FillingClosedGaps: false,
    Background: "#ffffff00",

    PageSize: "A4",

    Margins_Left_Right: 5,
    Margins_Top_Bottom: 5,
    Margins_Left: 5,
    Margins_Top: 5,
    Margins_Bottom: 5,
    Margins_Right: 5,

    CustomPageWidth: 100,
    CustomPageHeight: 100,
}

const sizePapers: SizePapersType = {
    A1: [594, 841],
    A2: [420, 594],
    A3: [297, 420],
    A4: [210, 297],
    A5: [148, 210],
}

function PreviewOnSheetForm() {

    const [form] = Form.useForm();
    const [image, setImage] = useState<string | null>()

    const tabs = useAppSelector(state => state.page.tabs)
    const previewImage = useAppSelector(state => state.page.previewImage)
    const generateList = useAppSelector(state => state.page.generateList)

    const [previewCurrentPage, setPreviewCurrentPage] = useState<number>(0)
    const [allowPreview, setAllowPreview] = useState<boolean>(false)
    const [detailedMarginsVisible, setDetailedMarginsVisible] = useState(false)
    const [isGeneration, setIsGeneration] = useState(true)
    const [isColorPickerSelected, setIsColorPickerSelected] = useState<boolean>(false)
    const [isCustomPageSize, setIsCustomPageSize] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const {id} = useParams()
    const {tab} = useParams()

    const convertDimensionsToRequest = (arr: FormSettingsType) => {

        const margin = []
        if (detailedMarginsVisible) {
            margin[0] = arr.Margins_Top
            margin[1] = arr.Margins_Right
            margin[2] = arr.Margins_Bottom
            margin[3] = arr.Margins_Left
        } else {
            margin[0] = arr.Margins_Top_Bottom
            margin[1] = arr.Margins_Left_Right
            margin[2] = arr.Margins_Top_Bottom
            margin[3] = arr.Margins_Left_Right
        }

        let pageSize
        if (isCustomPageSize) {
            pageSize = [arr?.CustomPageWidth, arr?.CustomPageHeight]
        } else {
            pageSize = sizePapers[arr?.PageSize]
        }

        const newArr: any = {
            Background: arr.Background,
            Copies: arr.Copies,
            FillingClosedGaps: arr.FillingClosedGaps,
            ReducingMethod: arr.ReducingMethod,
            PageSize: pageSize,
            PageMargins: margin
        }

        return {...newArr}
    }

    const generate = (data: FormSettingsType) => {

        const newCurrentPage = 0
        setPreviewCurrentPage(newCurrentPage)
        const newData: SettingsType = convertDimensionsToRequest(data)

        if (id !== undefined) {
            setIsGeneration(true)

            const args = {id: id, tab: tab + "", data: newData}

            dispatch(generateAction(args)).unwrap().then(() => {
                setAllowPreview(true)
                if (id && tab) {

                    dispatch(getPreviewAction({
                        id: id,
                        tab: tab,
                        numberPage: newCurrentPage
                    })).unwrap().then(() => {
                        setIsGeneration(false)
                    }).catch(() => {
                        setIsGeneration(false)
                    })
                }
            }).catch(() => {
                setIsGeneration(false)
            })
        }
    }

    const getTabs = () => {
        if (id !== undefined) {

            dispatch(setLoadingAction(true))
            dispatch(getTabNamesAction(id)).unwrap().then(() => {

                dispatch(setLoadingAction(false))
            })
        }
    }

    useEffect(() => {
        getTabs()
        generate(initialFormValues)
    }, [id])

    useEffect(() => {
        if (previewImage) {
            let reader = new window.FileReader();
            reader.readAsDataURL(previewImage);
            reader.onload = function () {
                setImage(reader.result + "")
            }
        }
    }, [previewImage])

    useEffect(() => {
        if (allowPreview && id && tab) {
            dispatch(getPreviewAction({
                id: id,
                tab: tab,
                numberPage: previewCurrentPage
            })).unwrap().then(() => {
                setIsGeneration(false)
            }).catch(() => {
                setIsGeneration(false)
            })
        }

    }, [previewCurrentPage, allowPreview])


    const marginsVisibleHandler = () => {
        setDetailedMarginsVisible(!detailedMarginsVisible)
    };

    const onFinish = (values: any) => {
        // console.log(values)
        generate(values)
    };

    const onActionFromHandler = () => {
        // form.submit()
    };

    const onChangePageSize = (pageSize: any) => {
        if (pageSize === "Custom") {
            setIsCustomPageSize(true)
        } else {
            setIsCustomPageSize(false)
        }
    };

    const findUnallocatedFolderName = () => {
        let newFolder = "Tab";
        let count = 1;
        while (tabs.includes(newFolder + "" + count)) {
            count++;
        }
        return newFolder + "" + count
    }

    const download = () => {
        if (id !== undefined) {
            const FileLink = document.createElement('a');
            FileLink.href = `${api}/processings/${id}/folders/${tab}/download`;
            FileLink.download = 'my-filename.ext';
            document.body.appendChild(FileLink);
            FileLink.click();
            FileLink.remove();
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const changePage = (action: any) => {
        const nextPage = previewCurrentPage + action

        if (nextPage >= 0 && nextPage <= generateList.length - 1) {
            setPreviewCurrentPage(nextPage)
        }
    };

    const moveFigures = (targetTabMove: string) => {
        const args = {
            idProcessing: id + "",
            tab: tab + "",
            numberPage: previewCurrentPage,
            targetTabMove
        }

        dispatch(moveFiguresFromPageAction(args)).unwrap().then(() => {
            message.info("moved to " + targetTabMove)
            getTabs()
            form.submit()
        }).catch(() => {

        })
    }

    const [items, setItems] = useState<MenuProps['items']>([])

    useEffect(() => {
        setItems([...tabs, findUnallocatedFolderName()].map((tab, index) => {
            return {
                label: <span onClick={() => {
                    moveFigures(tab)
                }}>{tab}  {tab === findUnallocatedFolderName() ? "+": ""}</span>,
                key: index
            }
        }))
    }, [tabs, previewCurrentPage])

    return (
        <Component>
            <Row>
                <ImageBlock>
                    <ExitButton $disabled={isGeneration} to={`/preview/${id}/${tab}`}>
                        <ArrowExitSvg/>
                    </ExitButton>

                    <ImageWrapper>
                        {
                            isGeneration ?
                                <Spin spinning={true}/> :
                                <Zoom>
                                    <img src={image ? image : ""} alt="figure"/>
                                </Zoom>
                        }
                    </ImageWrapper>
                    <Actions $disabled={isGeneration}>
                        <Action onClick={() => changePage(-1)}>
                            <Arrow1Svg style={{transform: `rotate(180deg)`}}/>
                        </Action>
                        {(previewCurrentPage + 1) + " / " + generateList.length}

                        <SubGroupActions>
                            <Dropdown menu={{items}} trigger={['click']}
                                      dropdownRender={(el) => {
                                          return <DropDownWrapper><PointerCloudSvg/>{el}</DropDownWrapper>
                                      }}
                                      placement="bottomRight"
                                      disabled={tabs.length <= 0}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <Action>
                                            <CornerUpRightSvg style={{height: `calc(24px * ${scale})`}}/>
                                        </Action>
                                    </Space>
                                </a>
                            </Dropdown>

                            <Action onClick={() => changePage(1)}><Arrow1Svg/></Action>
                        </SubGroupActions>
                    </Actions>
                </ImageBlock>

                <SettingsBlock>
                    <TitlesBlock>
                        <Title>Print</Title>
                        <Title>Settings</Title>
                    </TitlesBlock>
                    <StyledForm
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                        colon={false}
                        initialValues={initialFormValues}
                        disabled={isGeneration}
                    >
                        <Form.Item
                            label="Reducing method"
                            name="ReducingMethod"
                            style={{display: 'none'}}
                        >
                            <Select suffixIcon={<ChevronDownSvg/>}
                                    dropdownRender={(el) => {
                                        return <SelectWrapper><PointerCloudSvg/>{el}</SelectWrapper>
                                    }}
                                    dropdownStyle={StyleSelect}
                                    onChange={onActionFromHandler}
                            >
                                <Option value="Cutoff">Cutoff</Option>
                                <Option value="Squeeze">Squeeze</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="Copies"
                            label="Copies"
                            className={"short"}
                            style={{display: 'none'}}
                            validateTrigger="onBlur"
                            rules={[{required: true, message: 'Please input Copies!'}]}
                        >
                            <InputNumber
                                controls={{
                                    upIcon: <ChevronDownSvg style={{transform: "rotate(180deg)"}}/>,
                                    downIcon: <ChevronDownSvg/>
                                }}
                                onBlur={onActionFromHandler}
                                min={1}/>
                        </Form.Item>

                        <ConfigProvider
                            theme={{
                                components: {
                                    Switch: {
                                        handleBg: "#DB5C34",
                                        handleShadow: `calc(0px * ${scale}) calc(0px * ${scale}) calc(5px * ${scale}) rgba(0, 0, 0, 0.30)`,
                                        handleSize: 33 * scale,
                                        trackMinWidth: 105 * scale,
                                        trackHeight: 50 * scale,
                                        trackPadding: 6 * scale,
                                    },
                                },
                            }}
                        >
                            <StyledSwitchWrapper
                                name="FillingClosedGaps"
                                label="Filling closed gaps"
                                className={"height50"}
                                style={{display: 'none'}}
                            >
                                <StyledSwitch
                                    onChange={onActionFromHandler}
                                    checkedChildren="ON" unCheckedChildren="OFF" defaultChecked
                                />
                            </StyledSwitchWrapper>
                        </ConfigProvider>

                        <Form.Item
                            label="Page size"
                            name="PageSize"
                        >
                            <Select suffixIcon={<ChevronDownSvg/>}
                                    dropdownRender={(el) => {
                                        return <SelectWrapper><PointerCloudSvg/>{el}</SelectWrapper>
                                    }}
                                    dropdownStyle={StyleSelect}
                                    onChange={onChangePageSize}
                            >
                                <Option value="A3">A3</Option>
                                <Option value="A4">A4</Option>
                                <Option value="A5">A5</Option>
                                <Option value="Custom">Custom</Option>
                            </Select>
                        </Form.Item>

                        {isCustomPageSize &&
                            <SettingsField>
                                <SettingsFieldLabel>Custom</SettingsFieldLabel>
                                <CustomSizeFiled>
                                    <Form.Item
                                        name="CustomPageWidth"
                                    >
                                        <InputNumber
                                            min={1}
                                            placeholder={"width"}/>

                                    </Form.Item>
                                    <Form.Item
                                        name="CustomPageHeight"
                                    >
                                        <InputNumber
                                            min={1}
                                            placeholder={"height"}/>

                                    </Form.Item>
                                </CustomSizeFiled>
                            </SettingsField>
                        }

                        <SettingsField>
                            <SettingsFieldLabel>Margins</SettingsFieldLabel>

                            <MarginFiled>
                                <MarginSquareSvg onClick={marginsVisibleHandler}/>
                                {!detailedMarginsVisible &&
                                    <MarginSettings>
                                        <Form.Item
                                            name="Margins_Left_Right"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftRightSvg/>}
                                                placeholder={"mm"}/>
                                        </Form.Item>
                                        <Form.Item
                                            name="Margins_Top_Bottom"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftRightSvg
                                                    style={{transform: "rotate(90deg)"}}
                                                />}
                                                placeholder={"mm"}/>
                                        </Form.Item>
                                    </MarginSettings>
                                }

                                {detailedMarginsVisible &&
                                    <MarginSettings>
                                        <Form.Item
                                            name="Margins_Left"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftSvg/>}
                                                placeholder={"mm"}/>
                                        </Form.Item>
                                        <Form.Item
                                            name="Margins_Top"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftSvg
                                                    style={{transform: "rotate(90deg)"}}
                                                />}
                                                placeholder={"mm"}/>
                                        </Form.Item>

                                        <Form.Item
                                            name="Margins_Bottom"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftSvg
                                                    style={{transform: "rotate(-90deg)"}}
                                                />}
                                                placeholder={"mm"}/>
                                        </Form.Item>
                                        <Form.Item
                                            name="Margins_Right"
                                        >
                                            <InputNumber
                                                onBlur={onActionFromHandler}
                                                min={0}
                                                max={15}
                                                addonBefore={<MarginLeftSvg
                                                    style={{transform: "rotate(180deg)"}}
                                                />}
                                                placeholder={"mm"}/>
                                        </Form.Item>
                                    </MarginSettings>
                                }
                            </MarginFiled>
                        </SettingsField>

                        <ColorPikerFormItem
                            label="Background"
                            name="Background"
                        >
                            <Radio.Group
                                onChange={() => {
                                    onActionFromHandler()
                                    setIsColorPickerSelected(false)
                                }}>

                                {colors.map((value, index) => {
                                    return (
                                        <ColorWrap>
                                            <ColorPikerRadio key={index} $color={value} value={value}/>
                                            <CheckedSvg/>
                                        </ColorWrap>)
                                })}

                                <ColorWrap>
                                    <StyledColorPicker
                                        $isColorPickerSelected
                                        onChangeComplete={(color) => {
                                            form.setFieldValue("Background", color?.toHexString())
                                            setIsColorPickerSelected(true)
                                            onActionFromHandler()
                                        }}/>
                                    <CheckedSvg style={{display: isColorPickerSelected ? "block" : "none"}}/>
                                </ColorWrap>
                            </Radio.Group>
                        </ColorPikerFormItem>
                        {isColorPickerSelected}

                        <Buttons>
                            <Button onClick={download}>
                                Download
                            </Button>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Generate
                                </Button>
                            </Form.Item>
                        </Buttons>

                    </StyledForm>
                </SettingsBlock>
            </Row>
        </Component>
    );
}

export default PreviewOnSheetForm;