import React from 'react';
import styled from "styled-components";
import LoginPart1Png from "../images/LoginBackground.jpg";
import LoginForm from "../components/LoginForm";

const Page = styled.div`
  width: 100%;
  padding-top: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  background:  url(${LoginPart1Png}) no-repeat;
  background-size: cover;
`;

function Login() {
    return (
        <Page>
            <LoginForm/>
        </Page>
    );
}

export default Login;