import React from 'react';
import LogOutSvg from "../images/logOutSvg";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useAppSelector} from "../redux/hooks";
import {api, scale} from "../config";
import moment from "moment";

const Component = styled.div`
  background: #DB5C34;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: calc(15px * ${scale}) 0 calc(17px * ${scale});
`;

const Content = styled.div`
  display: flex;
  gap: calc(40px * ${scale});
  align-items: center;
  justify-content: flex-end;
  max-width: calc(1180px * ${scale});
  width: 100%;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(11px * ${scale});
  align-items: flex-start;
`;

const Username = styled.div`
  color: #FAF3E9;
  text-align: center;
  font-family: "Krona One", serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.calc(4px * ${scale} */
  
  text-transform: uppercase;
`;

const UserExpire = styled.div`
  color: rgba(250, 243, 233, 0.70);
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale} */
`;
const LogOutButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & > svg {
    width: calc(48px * ${scale});
    height: calc(48px * ${scale});
    
    & > path {
      stroke: #FAF3E9
    }
  }

  &:hover {
    opacity: .8;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  width: calc(175px * ${scale});
  height: calc(42px * ${scale});
  justify-content: center;
  align-items: center;

  border-radius: calc(10px * ${scale});
  background: #1C1C1C;

  color: #FAF3E9;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale} */
  
  cursor: pointer;
  position: relative;
  
  text-decoration: none;
  
  &:hover {
    opacity: .8;
  }
  
  &:after {
    position: absolute;
    content: "";
    width: calc(2px * ${scale});
    height: calc(41px * ${scale});
    background: #FFF;
    right: calc(-20px * ${scale});
  }
`;


function TopMenuUser(props:any) {

    const user = useAppSelector(state => state.page.user)

    const menu2 = props.type === "menu2";
    const projectList = props.type === "projectList";

    const menu = <StyledLink to={`/projects`}>Projects</StyledLink>
    const projectListMenu = <StyledLink to={`/settings`}>New Project</StyledLink>

    return (
        <Component {...props}>
            <Content>
                {menu2 && menu}
                {projectList && projectListMenu}
                <User>
                    <Username>{user?.Name || user?.Login}</Username>
                    <UserExpire>Expire at {moment(user?.ExpirationDate).format("DD.MM.YYYY")}</UserExpire>
                </User>
                <a href={`${api}/exit`}>
                    <LogOutButton>
                        <LogOutSvg/>
                    </LogOutButton>
                </a>
            </Content>
        </Component>
    );
}

export default TopMenuUser;