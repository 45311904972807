import React, {useEffect} from 'react';
import styled from "styled-components";
import {Button, Form, Input, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {loginAction} from "../redux/pageSlice";
import {RoleType} from "../utils/types";
import { scale } from '../config';

const Component = styled.div`
  padding: calc(50px * ${scale}) calc(27px * ${scale}) calc(20px * ${scale});
  border-radius: calc(30px * ${scale});
  background: #FAF3E9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  color: #1C1C1C;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(14px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.calc(6px * ${scale}) */

  & > h1 {
    font-family: Krona One, serif;
    font-size: calc(29px * ${scale});
    font-weight: 400;
    margin-bottom: calc(20px * ${scale});
  }


`;

const StyledForm = styled(Form)`
  margin-top: calc(40px * ${scale});
  width: 100%;
  max-width: calc(244px * ${scale}) !important;
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: calc(30px * ${scale});
  }
`;

const FormRow = styled.div`
  width: 100%;
  gap: calc(20px * ${scale});
  display: flex;
  justify-content: space-between;

  & .ant-form-item {
    width: 100%;

    & .ant-col {
      max-width: 100%;

      input {
        color: #000;
        text-align: center;
        font-family: Roboto, serif;
        font-size: calc(17px * ${scale});
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 23.calc(8px * ${scale}) */

        padding: calc(20px * ${scale}) calc(24px * ${scale}) calc(18px * ${scale});
        border-radius: calc(20px * ${scale});
        border: calc(4px * ${scale}) solid #1C1C1C;
        box-shadow: 0 0 0 0;

        &::placeholder {

          color: #787878;
        }
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: calc(17px * ${scale});
  justify-content: center;
  margin-top: calc(15px * ${scale});
`;

const ButtonSave = styled(Form.Item)`

  width: 100%;
  height: calc(60px * ${scale});

  & .ant-col {
    margin: 0;
    max-width: 100%;
  }

  & button {
    width: 100%;
    height: calc(60px * ${scale});

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    color: #000 !important;
    text-align: center;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 

    &:hover {
      background: #DB5C34 !important;
    }
  }
`;

function LoginForm() {

    const dispatch = useAppDispatch();

    const onFinish = (values: any) => {

        dispatch(loginAction({...values}))
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Component>
            <Text>
                <h1>Welcome back!</h1>
                Please login to your account
            </Text>
            <StyledForm
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <FormRow>
                    <Form.Item
                        name="login"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Username!',
                            },
                        ]}
                    >
                        <Input placeholder={"Username"}/>
                    </Form.Item>
                </FormRow>

                <FormRow>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Password!',
                            },
                        ]}
                    >
                        <Input placeholder={"Password"}/>
                    </Form.Item>
                </FormRow>

                <Buttons>
                    <ButtonSave
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Sign-in
                        </Button>
                    </ButtonSave>
                </Buttons>
            </StyledForm>
        </Component>
    );
}

export default LoginForm;