import React from 'react';

function PointerCloudSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
            <path d="M14 0L27.8564 24H0.143594L14 0Z" fill="#1C1C1C"/>
        </svg>
    );
}

export default PointerCloudSvg;