import React from 'react';

function LogOutSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M18 42H10C8.93913 42 7.92172 41.5786 7.17157 40.8284C6.42143 40.0783 6 39.0609 6 38V10C6 8.93913 6.42143 7.92172 7.17157 7.17157C7.92172 6.42143 8.93913 6 10 6H18" stroke="#DB935E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 34L42 24L32 14" stroke="#DB935E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M42 24H18" stroke="#DB935E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default LogOutSvg;