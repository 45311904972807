import {FigureType, ProcessingSettingsType, ProcessingType, SettingsType, UserType} from "../utils/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {api} from "../config";
import {message} from "antd";

type PageStateType = {
    user: UserType | null,
    users: UserType[],
    loading: boolean,
    processing: ProcessingType | null,
    processings: ProcessingType[],
    previewImage: Blob | null,
    figures: FigureType[],
    tabs: string[],
    generateList: string[]
    processingSettings: Record<number | string, ProcessingSettingsType>
}

const initialState: PageStateType = {
    user: null,
    loading: false,
    users: [],
    processing: null,
    processings: [],
    previewImage: null,
    figures: [],
    tabs: [],
    generateList: [],
    processingSettings: {}
}

export const loginAction = createAsyncThunk(
    "page/loginAction",
    async ({login, password}: { login: string, password: string }, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getProcessingSettingsAction = createAsyncThunk(
    "page/getProcessingSettingsAction",
    async (id: number | string, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/processings/${id}/settings`).then(({data}) => {
            return {...data, Id: id};
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const createUserAction = createAsyncThunk(
    "page/createUserAction",
    async (data: any, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/users`, data).then(() => {
            dispatch(getUsersAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getUserAction = createAsyncThunk(
    "page/getUserAction",
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/user`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getUsersAction = createAsyncThunk(
    "page/getUsersAction",
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/users`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const deleteUserAction = createAsyncThunk(
    "page/deleteUserAction",
    async (login: string, {dispatch, rejectWithValue}) => {
        return await axios.delete(`${api}/users/${login}`).then(() => {
            dispatch(getUsersAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const editUserAction = createAsyncThunk(
    "page/editUserAction",
    async ({
               Login,
               Email,
               Name,
               Password,
               Phone
           }: { Login: string, Email: string, Name: string, Password: string, Phone: string }, {
               dispatch,
               rejectWithValue
           }) => {
        return await axios.post(`${api}/users/${Login}`, {Email, Name, Password, Phone}).then(() => {
            dispatch(getUsersAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const uploadUserLogoAction = createAsyncThunk(
    "page/uploadUserLogoAction",
    async ({Login, file}: {Login:string | undefined, file:any}, {dispatch, rejectWithValue}) => {

        let data = new FormData();
        data.append('files',file.originFileObj, file.originFileObj.name);

        return await axios.post(`${api}/users/${Login}/logo`, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data;`,
            }
        }).then(() => {
            dispatch(getUsersAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const changeUserStateAction = createAsyncThunk(
    "page/changeUserStateAction",
    async ({Login, Value}: { Login: string, Value: string }, {dispatch, rejectWithValue}) => {

        return await axios.put(`${api}/users/${Login}/state/${Value}`).then(() => {
            dispatch(getUsersAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getProcessingsAction = createAsyncThunk(
    "page/getProcessingsAction",
    async (_, {dispatch, rejectWithValue}) => {

        return await axios(`${api}/processings`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const deleteProcessingAction = createAsyncThunk(
    "page/deleteProcessingAction",
    async (Id: number | string, {dispatch, rejectWithValue}) => {

        return await axios.delete(`${api}/processings/${Id}`).then(({data}) => {
            dispatch(getProcessingsAction())
            return;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const newProcessingAction = createAsyncThunk(
    "page/newProcessingAction",
    async (data: SettingsType, {dispatch, rejectWithValue}) => {

        return await axios.post(`${api}/processings`, data).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const uploadLogoAction = createAsyncThunk(
    "page/uploadLogoAction",
    async ({id, file}:{id: number | string, file: any}, {dispatch, rejectWithValue}) => {

        let data = new FormData();
        data.append('files',file.originFileObj, file.originFileObj.name);

        return await axios.post(`${api}/processings/${id}/logo`, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data;`,
            }
        }).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })

    }
)

export const getProcessingAction = createAsyncThunk(
    "page/getProcessingAction",
    async (id: number | string, {dispatch, rejectWithValue}) => {

        return await axios(`${api}/processings/${id}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getFigureTabAction = createAsyncThunk(
    "page/getFigureTabAction",
    async ({id, tab}:{id: number | string, tab: string}, {dispatch, rejectWithValue}) => {

        return await axios(`${api}/processings/${id}/folders/${tab}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const editCountsAction = createAsyncThunk(
    "page/editCountsAction",
    async ({Id, Tab, Figures}: { Id: string | number, Tab:string, Figures: FigureType[]} , {dispatch, rejectWithValue}) => {
        return await axios.patch(`${api}/processings/${Id}/folders/${Tab}`, Figures).then(({data}) => {
            return {data, Tab};
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const addPhotoAction = createAsyncThunk(
    "page/addPhotoAction",
    async ({Id, Files, service, TabName}:{ Id: number | string, service?: string, Files: any, TabName: string}, {dispatch, rejectWithValue}) => {

        let data = new FormData();

        if(service) {
            // data.append('service', "service");
            data.append('service', service);
        }

        for (let i = 0; i < Files.length; i++) {
            data.append('files', Files[i].originFileObj, Files[i].originFileObj.name);
        }

        return await axios.post(`${api}/processings/${Id}/folders/${TabName}`, data).then(({data}) => {
            return {data, Tab: TabName};
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const generateAction = createAsyncThunk(
    "page/generateAction",
    async ({id, tab, data}:{id: number | string, tab: string, data: SettingsType}, {dispatch, rejectWithValue}) => {

        return await axios.post(`${api}/processings/${id}/folders/${tab}/generate`, data).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getPreviewAction = createAsyncThunk(
    "page/getPreviewAction",
    async ({id, tab, numberPage}:{id: number | string, tab: string, numberPage: number | string}, {dispatch, rejectWithValue}) => {

        return await axios.get(`${api}/processings/${id}/folders/${tab}/preview/${numberPage}`, {responseType: "blob"}).then(({data}) => {

            return data

        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getTabNamesAction = createAsyncThunk(
    "page/getTabNamesAction",
    async (Id: number | string, {dispatch, rejectWithValue}) => {

        return await axios.get(`${api}/processings/${Id}/folders`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const deleteFigureAction = createAsyncThunk(
    "page/deleteFigureAction",
    async ({idProcessing, tab, idFigure}:{idProcessing: number | string, tab: string, idFigure: number | string}, {dispatch, rejectWithValue}) => {

        return await axios.delete(`${api}/processings/${idProcessing}/folders/${tab}/${idFigure}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const moveFigureAction = createAsyncThunk(
    "page/moveFigureAction",
    async ({idProcessing, tab, idFigure, targetTabMove}:{idProcessing: number | string, tab: string, idFigure: number | string, targetTabMove: string}, {dispatch, rejectWithValue}) => {

        return await axios.patch(`${api}/processings/${idProcessing}/folders/${tab}/${idFigure}/move/${targetTabMove}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const moveFiguresFromPageAction = createAsyncThunk(
    "page/moveFiguresFromPageAction",
    async ({idProcessing, tab, numberPage, targetTabMove
    }:{idProcessing: number | string, tab: string, numberPage: number | string, targetTabMove: string}, {dispatch, rejectWithValue}) => {

        return await axios.patch(`${api}/processings/${idProcessing}/folders/${tab}/page/${numberPage}/move/${targetTabMove}`).then(({data}) => {
            return data;
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)


export const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        changeAction(state, action) {
            state.user = null
        },
        setLoadingAction(state, action) {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginAction.fulfilled, (state, action) => {
            state.user = action.payload
        }).addCase(loginAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(getUserAction.fulfilled, (state, action) => {
            state.user = action.payload
            state.loading = false
        }).addCase(getUserAction.pending, (state, action) => {
            state.loading = true
        }).addCase(getUserAction.rejected, (state, action: any) => {
            state.loading = false

        }).addCase(getUsersAction.fulfilled, (state, action) => {
            state.users = action.payload

        }).addCase(createUserAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(deleteUserAction.rejected, (state, action: any) => {
            message.error(action.payload)
        }).addCase(deleteUserAction.fulfilled, (state, action: any) => {
            message.warning("The user has been successfully deleted!")

        }).addCase(editUserAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(changeUserStateAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(getProcessingsAction.fulfilled, (state, action: any) => {
            state.processings = action.payload

        }).addCase(newProcessingAction.fulfilled, (state, action: any) => {
            state.processing = action.payload
            state.loading = false
        }).addCase(newProcessingAction.pending, (state, action) => {
            state.loading = true
        }).addCase(newProcessingAction.rejected, (state, action: any) => {
            message.error(action.payload)
            state.loading = false

        }).addCase(getProcessingAction.fulfilled, (state, action: any) => {
            state.processing = action.payload
        }).addCase(getProcessingAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(editCountsAction.fulfilled, (state, action: any) => {
            const tab = action.payload.Tab
            const data = action.payload.data
            state.figures = data.Figures[tab + ""]

        }).addCase(editCountsAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(addPhotoAction.fulfilled, (state, action: any) => {
            const tab = action.payload.Tab
            const data = action.payload.data
            state.figures = data.Figures[tab + ""]

            state.loading = false
        }).addCase(addPhotoAction.pending, (state, action) => {
            state.loading = true
        }).addCase(addPhotoAction.rejected, (state, action: any) => {
            message.error(action.payload)
            state.loading = false

        }).addCase(generateAction.fulfilled, (state, action: any) => {
            state.generateList = action.payload
            // state.loading = false
        }).addCase(generateAction.pending, (state, action) => {
            // state.loading = true
        }).addCase(generateAction.rejected, (state, action: any) => {
            message.error(action.payload)
            // state.loading = false

        }).addCase(getPreviewAction.fulfilled, (state, action: any) => {
            state.previewImage = action.payload;
        }).addCase(getPreviewAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(uploadLogoAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(deleteProcessingAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(getFigureTabAction.fulfilled, (state, action: any) => {
            state.figures = action.payload;
        }).addCase(getFigureTabAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(getTabNamesAction.fulfilled, (state, action: any) => {
            state.tabs = action.payload;
        }).addCase(getTabNamesAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(deleteFigureAction.fulfilled, (state, action: any) => {
            state.figures = action.payload;
        }).addCase(deleteFigureAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(moveFigureAction.fulfilled, (state, action: any) => {
            state.figures = action.payload;
        }).addCase(moveFigureAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(getProcessingSettingsAction.fulfilled, (state, action: any) => {
            state.processingSettings = {...state.processingSettings, [action.payload.Id]:action.payload};
        }).addCase(getProcessingSettingsAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(uploadUserLogoAction.fulfilled, (state, action: any) => {

        }).addCase(uploadUserLogoAction.rejected, (state, action: any) => {
            message.error(action.payload)

        }).addCase(moveFiguresFromPageAction.fulfilled, (state, action: any) => {

        }).addCase(moveFiguresFromPageAction.rejected, (state, action: any) => {
            message.error(action.payload)

        })
    }
})

export const {setLoadingAction} = pageSlice.actions
export default pageSlice.reducer
