import React from 'react';
import styled from "styled-components";
import {Button, Form, Upload} from "antd";
import {useAppDispatch} from "../redux/hooks";
import {addPhotoAction} from "../redux/pageSlice";
import {useNavigate, useParams} from "react-router-dom";
import { scale } from '../config';

const Component = styled.div`
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: calc(80px * ${scale});
  }
`;


const FormUploadsContainer = styled.div`
  width: 100%;

  & .ant-form-item {
    & .ant-col {
      max-width: 100%;

      .ant-upload-wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: calc(20px * ${scale});

        .ant-upload-select {
          order: 10000;

          height: calc(218px * ${scale});
          width: 100%;

          border-radius: calc(10px * ${scale});
          cursor: pointer;
          border: calc(3px * ${scale}) dashed #A5A5A5;

          .ant-upload {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;

            button {

              cursor: pointer;
            }
          }

          &:hover {
            border: calc(3px * ${scale}) dashed #DB5C34;
          }
        }
      }
    }
  }
`;

const Buttons = styled.div`
  height: 100%;
  display: flex;
  gap: calc(17px * ${scale});
  justify-content: flex-end;

`;

const ButtonSave = styled(Form.Item)`

  width: calc(300px * ${scale}) !important;
  height: calc(67.7px * ${scale});


  & .ant-col {
    margin: 0;
    max-width: 100%;
  }

  & button {
    width: 100%;
    height: calc(67.7px * ${scale});

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    color: #000 !important;
    text-align: center;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    &:hover {
      background: #DB5C34 !important;
    }
  }
`;


const FormRow = styled.div`
  width: 100%;
  gap: calc(20px * ${scale});
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .ant-form-item {

    width: calc(440px * ${scale});

    & .ant-col {
      max-width: 100%;

      input {
        color: #000;
        font-family: Roboto, serif;
        font-size: calc(17px * ${scale});
        font-style: normal;
        font-weight: 500;
        line-height: 140%; 

        padding: calc(20px * ${scale}) calc(24px * ${scale}) calc(18px * ${scale});
        border-radius: calc(10px * ${scale});
        border: calc(3px * ${scale}) solid #1C1C1C;
        box-shadow: 0 0 0 0;

        &::placeholder {

          color: #787878;
        }
      }
    }
  }
`;

const UploadText = styled.div`
  user-select: none;
  pointer-events: none;

  color: #A5A5A5;
  text-align: center;
  font-family: Krona One, serif;
  font-size: calc(24px * ${scale}) !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 

  & > span {
    color: #DB5C34;
    font-family: Krona One, serif;
    font-size: calc(24px * ${scale});
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

function LoadingForm() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id} = useParams()

    const onFinish = (values: any) => {
        console.log(values);
        const tabName = "Tab1"

        dispatch(addPhotoAction({Id: id+"", Files: [...values.Files], TabName: tabName})).unwrap().then((e)=>{
            const data = e.data
            const folderNames = Object.keys(data.Figures)
            navigate(`/preview/${data.Id}/${folderNames[0]}`);
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Component>
            <StyledForm
                name="basic"
                labelCol={{
                    span: 8 * scale,
                }}
                wrapperCol={{
                    span: 16 * scale,
                }}
                style={{
                    maxWidth: 600 * scale,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <FormRow>
                    <Buttons>
                        <ButtonSave
                            wrapperCol={{
                                offset: 8 * scale,
                                span: 16 * scale,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Upload
                            </Button>
                        </ButtonSave>
                    </Buttons>
                </FormRow>

                <FormUploadsContainer>
                    <Form.Item name="Files" valuePropName="fileList" getValueFromEvent={normFile}
                               rules={[
                                   {
                                       required: true,
                                       message: 'Please upload photo!',
                                   },
                               ]}
                    >
                        <Upload customRequest={({onSuccess}) => {
                            onSuccess && onSuccess("окей")
                        }}
                                multiple
                                accept="image/png, image/jpeg" listType="picture">
                            <button style={{border: 0, background: 'none'}} type="button">
                                <UploadText>
                                    Drag a file here or <span> browse </span> for a file to upload
                                </UploadText>
                            </button>
                        </Upload>
                    </Form.Item>
                </FormUploadsContainer>



            </StyledForm>
        </Component>
    );
}

export default LoadingForm;