import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Modal, Upload, UploadFile} from 'antd';
import TrashCanSvg from "../images/trashCanSvg";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    changeUserStateAction,
    createUserAction,
    deleteUserAction,
    editUserAction,
    getUsersAction,
    uploadUserLogoAction
} from "../redux/pageSlice";
import {UserType} from "../utils/types";
import Login from "../pages/Login";
import CalendarSvg from "../images/calendarSvg";
import {scale} from '../config';

const Component = styled.div`
  width: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-bottom: calc(82px * ${scale});

  border-radius: calc(30px * ${scale});
  border: calc(3px * ${scale}) solid #1C1C1C;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & > thead {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% - calc(112px * ${scale}));
      margin: 0 calc(56px * ${scale});
      bottom: calc(30px * ${scale});
      border-bottom: calc(1px * ${scale}) solid #1C1C1C;
    }
  }
`;

const Tr = styled.tr`

  & > th, & > td {
    width: 25%;

    &:first-child {
      width: 33%;
      padding-left: calc(100px * ${scale});
    }

    &:last-child {
      padding-right: calc(80px * ${scale});
    }
  }

  &:hover > td {
    background: rgba(225, 181, 90, 0.30);
  }
`;

const Th = styled.th`
  padding: calc(8px * ${scale});
  text-align: left;
  height: calc(132px * ${scale});

  color: #A5A5A5;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale}) */
  text-transform: uppercase;
`;

const Td = styled.td`
  padding: calc(8px * ${scale});
  height: calc(60px * ${scale});

  color: #1C1C1C;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.calc(4px * ${scale}) */
`;

const Actions = styled.div`
  display: flex;
  gap: calc(30px * ${scale});
`;

const Action = styled.div`
  border-radius: calc(10px * ${scale});
  background: #E1B55A;

  display: flex;
  width: calc(100px * ${scale});
  height: calc(39px * ${scale});
  justify-content: center;
  align-items: center;

  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale}) */

  cursor: pointer;
  user-select: none;

  &:hover {
    background: #DB5C34;
  }
`;

const AddActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: calc(82px * ${scale});
`;

const AddAction = styled.div`
  border-radius: calc(10px * ${scale});
  background: #E1B55A;

  display: flex;
  width: calc(150px * ${scale});
  height: calc(50px * ${scale});
  justify-content: center;
  align-items: center;

  margin-top: calc(20px * ${scale});

  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale}) */

  cursor: pointer;
  user-select: none;

  &:hover {
    background: #DB5C34;
  }
`;

const ModalDeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 calc(50px * ${scale});

  color: #FAF3E9;
  font-family: Roboto, serif;
  font-size: calc(16px * ${scale});
  font-weight: 500;
  line-height: 140%;

  & > svg {
    width: calc(80px * ${scale});
    height: calc(80px * ${scale});

    & > path {
      stroke: #FAF3E9;
    }
  }

  & > b {
    margin: calc(30px * ${scale}) 0 calc(20px * ${scale});

    font-family: Krona One, serif;
    font-size: calc(18px * ${scale});
    font-weight: 400;
  }
`;

const ModalEditContent = styled.div`
  width: calc(580px * ${scale});

  & > h3 {
    color: #FAF3E9;
    font-family: Krona One, serif;
    font-size: calc(18px * ${scale});
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.calc(2px * ${scale}) */
  }
`;

const StyledForm = styled(Form)`
  margin-top: calc(35px * ${scale});
  width: 100%;
`;

const FormRow = styled.div`
  width: 100%;
  gap: calc(20px * ${scale});
  display: flex;
  justify-content: space-between;

  & .ant-form-item {
    width: 100%;

    & .ant-col {
      max-width: 100%;

      .ant-form-item-control-input-content input {
        font-family: Roboto, serif;
        font-size: calc(16px * ${scale});
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.calc(4px * ${scale}) */
      }

      .ant-form-item-control-input-content > input {
        padding: calc(20px * ${scale}) calc(24px * ${scale}) calc(18px * ${scale});
        border-radius: calc(20px * ${scale});
        border: calc(2px * ${scale}) solid #1C1C1C;
        background: #FAF3E9;

        box-shadow: 0 0 0 0;

        &::placeholder {
          color: #A5A5A5;
        }
      }

      .ant-picker {
        width: 100%;
        height: calc(62px * ${scale});
        padding: 0 calc(24px * ${scale});
        border-radius: calc(20px * ${scale});
        box-shadow: 0 0 0 0;

        .ant-picker-input {

          input {
            font-family: Roboto, serif;
            font-size: calc(16px * ${scale});
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.calc(4px * ${scale}) */
            box-shadow: 0 0 0 0;

            &::placeholder {
              color: #A5A5A5;
            }
          }
        }

        &:hover, &.ant-picker-focused {
          box-shadow: 0 0 0 0;
          border-color: #0000;
        }
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: calc(20px * ${scale});
  justify-content: center;
  margin-top: calc(70px * ${scale});
`;

const ButtonSave = styled(Form.Item)`
  width: calc(245px * ${scale});
  height: calc(60px * ${scale});

  & .ant-col {
    margin: 0;
    max-width: 100%;
  }

  & button {
    width: 100%;
    height: calc(60px * ${scale});

    border-radius: calc(20px * ${scale});
    background: #E1B55A;

    color: #000 !important;
    font-family: Roboto, serif;
    font-size: calc(17px * ${scale});
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 23.calc(8px * ${scale}) */

    &:hover {
      background: #DB5C34 !important;
    }
  }
`;

const ButtonCancelModal = styled.div`
  width: calc(245px * ${scale});
  height: calc(60px * ${scale});

  border-radius: calc(20px * ${scale});
  color: #FAF3E9;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale}) */

  cursor: pointer;

  border: calc(3px * ${scale}) solid #E1B55A;
  background: #0000;

  &:hover {
    border: calc(3px * ${scale}) solid #DB5C34;
  }
`;

const ModalDelete = styled(Modal)`
`;

const ModalDeleteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(50px * ${scale});
  width: calc(510px * ${scale});
`;

const ModalDeleteButton = styled.div`
  color: #000;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(17px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.calc(8px * ${scale}) */

  display: flex;
  width: calc(245px * ${scale});
  padding: calc(17px * ${scale}) calc(36px * ${scale});
  justify-content: center;
  align-items: center;

  border-radius: calc(20px * ${scale});
  background: #E1B55A;

  cursor: pointer;

  &:hover {
    background: #DB5C34;
  }

  &:first-child {
    color: #FAF3E9;

    border: calc(3px * ${scale}) solid #E1B55A;
    background: #0000;

    &:hover {
      border: calc(3px * ${scale}) solid #DB5C34;
    }
  }
`;


const Switch = styled.label`
  width: calc(62px * ${scale});
  height: calc(29px * ${scale});
  display: block;
  border-radius: calc(40px * ${scale});
  border: calc(2px * ${scale}) solid #1C1C1C;

  color: #1C1C1C;
  text-align: center;
  font-family: Roboto, serif;
  font-size: calc(14px * ${scale});
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  overflow: hidden;

  cursor: pointer;

  position: relative;

  & > span {
    height: 100%;
    display: flex;
    align-items: center;

    transition: .3s;

    position: absolute;
    left: calc(-27px * ${scale});

    user-select: none;

    & > div {
      width: calc(18px * ${scale});
      height: calc(18px * ${scale});
      aspect-ratio: 1 / 1;
      box-shadow: 0 0 calc(5px * ${scale}) rgba(0, 0, 0, 0.30);
      border-radius: calc(100px * ${scale});
      background: #DB5C34;

      margin: 0 calc(6px * ${scale}) 0 calc(11px * ${scale});
    }

  }

  & > input {
    display: none;

    z-index: 2;

    &:checked + span {
      transition: .3s;
      left: calc(6px * ${scale});
    }
  }
`;


const UploadText = styled.div`
  display: flex;
  width: 100%;
  height: calc(60px * ${scale});
  justify-content: center;
  align-items: center;
  gap: calc(10px * ${scale});

  cursor: pointer;


  user-select: none;
  pointer-events: none;
  color: #A5A5A5;
  text-align: center;
  font-family: Krona One, serif;
  font-size: calc(16px * ${scale}) !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

`;


const ModalCreateContent = styled(ModalEditContent)`
`;


const FormUploadsContainer = styled.div<{$isListVisible:boolean}>`
  width: 100%;
  margin-bottom: calc(50px * ${scale});

  svg {
    path {
      fill: #a48d8d;
    }
  }

  height: calc(60px * ${scale}) !important;

  .ant-upload-list {

    display: ${props => props.$isListVisible ? "block": "none"};
    color: #A5A5A5;

    .ant-upload-list-item {
      margin: 0;
      height: calc(60px * ${scale}) !important;
      font-size: calc(14px * ${scale});

      .ant-upload-list-item-name {
        padding-left: 52px;
      }
    }
  }

  .ant-form-item-has-success {
    .ant-upload-list {

      .ant-upload-list-item-thumbnail {
        position: absolute;
        max-height: 100%;
        height: 100%;
      }
    }
  }

  & .ant-form-item {
    height: auto;

    & .ant-row {
      & .ant-col {
        max-width: 100%;
        height: auto;

        .ant-form-item-control-input-content {
          .ant-upload-wrapper {
            display: flex;
            flex-direction: column-reverse;
            gap: calc(20px * ${scale});

            .ant-upload-select {
              order: 10000;

              width: 100%;

              border-radius: calc(10px * ${scale});
              cursor: pointer;
              border: calc(3px * ${scale}) dashed #A5A5A5;

              .ant-upload {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;

                button {

                  cursor: pointer;
                }
              }

              &:hover {
                border: calc(3px * ${scale}) dashed #DB5C34;
              }
            }
          }
        }
      }
    }
  }
`;

const ModalEdit = styled(Modal)`
`;

const ModalCreate = styled(Modal)`

`;

const LogoImg = styled.img`
    height: 100%;
    max-width: 100%;
`;

const StyledCalendarSvg = styled(CalendarSvg)`

  width: calc(24px * ${scale});
  height: calc(24px * ${scale});
`;

const TableRow = ({data, showModalDelete, showModalEdit, setSwitch}: {
    data: UserType,
    showModalDelete: (Login: string) => void,
    showModalEdit: (User: UserType) => void,
    setSwitch: (Login: string, value: boolean) => void
}) => {
    return (
        <Tr>
            <Td>{data.Name}</Td>
            <Td>{data.Enabled ? "Active" : "Blocked"}</Td>
            <Td>{data?.LogoUrl ? <LogoImg src={`/${data?.LogoUrl}`}/>: "Empty"} </Td>
            <Td>
                <Switch>
                    <input onChange={() => {
                        setSwitch(data.Login, !data.Enabled)
                    }} checked={data.Enabled} type="checkbox"/>
                    <span><span>ON</span> <div/> <span>OFF</span></span>
                </Switch>
            </Td>
            <Td>
                <Actions>
                    <Action onClick={() => {
                        showModalEdit(data)
                    }}>Edit</Action>
                    <Action onClick={() => {
                        showModalDelete(data.Login)
                    }}>Delete</Action>
                </Actions>
            </Td>
        </Tr>
    );
};

const normFile = (e: any) => {
    return e?.file;
};

function TableUser() {

    const [formEdit] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const users = useAppSelector(state => state.page.users)
    const dispatch = useAppDispatch();


    useEffect(() => {
        dispatch(getUsersAction())
    }, [])

    useEffect(() => {
        // console.log(users);
    }, [users])

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    // delete
    const [loginDelete, setLoginDelete] = useState<string>("");

    const showModalDelete = (Login: string) => {
        setLoginDelete(Login)
        setOpenDelete(true);
    };

    const handleDeleteOk = () => {
        dispatch(deleteUserAction(loginDelete)).unwrap().then(() => {
            setLoginDelete("")
        })
        setOpenDelete(false);
    };

    const handleDeleteCancel = () => {
        setLoginDelete("")
        setOpenDelete(false);
    };

    // edit
    const [userEdit, setUserEdit] = useState<UserType | undefined>(undefined);

    const showModalEdit = (User: UserType) => {
        setUserEdit(User)
        setOpenEdit(true)
    };

    const closeModalEdit = () => {
        setOpenEdit(false)
    };

    useEffect(() => {
        resetFieldsEdit()
    }, [userEdit])


    useEffect(() => {
        // console.log(fileList);
    }, [fileList])


    const resetFieldsEdit = () => {
        formEdit.setFieldsValue({Logo: [], date:"", Note: "", ...userEdit, Password: ""})
        // formEdit.setFieldsValue({ date:"", Note: "", ...userEdit, Password: ""})
        setFileList([])

    };

    const handleEditCancel = () => {
        setUserEdit(undefined)
        closeModalEdit()
    };


    const onEditFinish = (values: any) => {
        const Login = userEdit?.Login
        delete values.Note
        values.Login = Login

        dispatch(editUserAction(values)).unwrap().then(() => {
            setUserEdit(undefined)
            closeModalEdit()

            uploadFile(Login, fileList)
        })
    };

    const uploadFile = (Login: string | undefined, files: any) => {
        if (files.length >= 1) {
            // message.info("uploadFile")
            const file = files[0]
            dispatch(uploadUserLogoAction({Login: Login, file: file}))
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        // console.log(errorInfo);
    };

    // Disable/Enable Switch
    const setSwitch = (Login: string, value: boolean) => {

        const newValue = value ? "enable" : "disable";
        dispatch(changeUserStateAction({Login, Value: newValue}))
    };


    // Create User +
    const [openCreate, setOpenCreate] = useState(false);

    const showModalCreate = () => {
        setOpenCreate(true)
    };

    const handleCreateCancel = () => {
        setOpenCreate(false)
    };

    const onFinishCreate = (values: any) => {

        dispatch(createUserAction({...values, Role: 30})).unwrap().then(() => {
            setOpenCreate(false)
        }).catch(() => {

        })
    };

    const onFinishFailedCreate = (errorInfo: any) => {
    };


    return (
        <Component>
            <ModalDelete
                classNames={{footer: "modalActionFooter"}}
                open={openDelete}
                centered
                closeIcon={null}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
                styles={{
                    mask: {background: "#0003"},
                    content: {
                        borderRadius: `${30 * scale}px`,
                        padding: `${50 * scale}px 0  ${44 * scale}px`,
                        background: "#1C1C1C"
                    },
                    footer: {display: "none", justifyContent: "center"}
                }}
                okText={"Delete"}
                width={`calc(780px * ${scale})`}
            >
                <ModalDeleteContent>
                    <TrashCanSvg/>
                    <b>Are you sure delete this user?</b>
                    If you delete the user you can't recover it.
                    <ModalDeleteButtons>
                        <ModalDeleteButton onClick={handleDeleteCancel}>Cancel</ModalDeleteButton>
                        <ModalDeleteButton onClick={handleDeleteOk}>Delete</ModalDeleteButton>
                    </ModalDeleteButtons>
                </ModalDeleteContent>
            </ModalDelete>

            <ModalEdit
                classNames={{footer: "modalActionFooter"}}
                open={openEdit}
                onCancel={handleEditCancel}
                centered
                closeIcon={null}
                styles={{
                    mask: {background: "#0003"},
                    content: {
                        background: "#1C1C1C",
                        borderRadius: `calc(30px * ${scale})`,
                        padding: `calc(70px * ${scale}) 0 calc(50px * ${scale})`,
                        display: "flex",
                        justifyContent: "center"
                    },
                    footer: {display: "none"}
                }}
                width={`calc(780px * ${scale})`}
            >
                <ModalEditContent>
                    <h3>Personal Details</h3>
                    <StyledForm
                        name="basic"
                        form={formEdit}
                        onFinish={onEditFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <FormRow>
                            <Form.Item
                                name="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Input placeholder={"First Name"}/>
                            </Form.Item>
                            <Form.Item
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Email!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Email"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>
                            <Form.Item
                                name="Phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Phone!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Phone"}/>
                            </Form.Item>
                            <Form.Item
                                name="Password"
                            >
                                <Input placeholder={"Password"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>
                            <Form.Item
                                name="Note"
                            >
                                <Input placeholder={"Note"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>
                            <Form.Item
                                name="date"
                            >
                                <DatePicker
                                    suffixIcon={<StyledCalendarSvg/>}
                                />
                            </Form.Item>
                        </FormRow>
                        <FormUploadsContainer $isListVisible={!!fileList.length}>
                            <Form.Item
                                name="Logo"
                                valuePropName="file"
                                getValueFromEvent={normFile}
                            >
                                <Upload customRequest={({onSuccess}) => {
                                    onSuccess && onSuccess("окей")
                                }}
                                        onChange={({fileList: newFileList}) => {
                                            setFileList(newFileList);
                                        }}
                                        onRemove={() => {
                                            setFileList([]);
                                        }}
                                        maxCount={1}
                                        accept="image/png, image/jpeg"
                                        listType="picture"
                                >
                                    {
                                        fileList.length === 0 &&
                                        <button style={{border: 0, background: 'none'}} type="button">
                                            <UploadText>
                                                <span>Change logo</span>
                                            </UploadText>
                                        </button>
                                    }
                                </Upload>
                            </Form.Item>
                        </FormUploadsContainer>

                        <Buttons>
                            <ButtonCancelModal onClick={handleEditCancel}>
                                Cancel
                            </ButtonCancelModal>
                            <ButtonSave
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </ButtonSave>
                        </Buttons>
                    </StyledForm>
                </ModalEditContent>
            </ModalEdit>


            <ModalCreate
                classNames={{footer: "modalActionFooter"}}
                open={openCreate}
                onCancel={handleCreateCancel}
                centered
                closeIcon={null}
                styles={{
                    mask: {background: "#0003"},
                    content: {
                        background: "#1C1C1C",
                        borderRadius: `calc(30px * ${scale})`,
                        padding: `calc(70px * ${scale}) 0 calc(50px * ${scale})`,
                        display: "flex",
                        justifyContent: "center"
                    },
                    footer: {display: "none"}
                }}
                width={`calc(780px * ${scale})`}
            >
                <ModalCreateContent>
                    <h3>Create user</h3>
                    <StyledForm
                        onFinish={onFinishCreate}
                        onFinishFailed={onFinishFailedCreate}
                        autoComplete="off"
                    >
                        <FormRow>
                            <Form.Item
                                name="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input First Name!',
                                    },
                                ]}
                            >
                                <Input placeholder={"First Name"}/>
                            </Form.Item>
                            <Form.Item
                                name="Login"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Login!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Login"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>
                            <Form.Item
                                name="Phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Phone!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Phone"}/>
                            </Form.Item>
                            <Form.Item
                                name="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Password!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Password"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>

                            <Form.Item
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Email!',
                                    },
                                ]}
                            >
                                <Input placeholder={"Email"}/>
                            </Form.Item>
                        </FormRow>

                        <FormRow>
                            <Form.Item
                                name="date"
                            >
                                <DatePicker
                                    suffixIcon={<CalendarSvg/>}
                                />
                            </Form.Item>
                        </FormRow>


                        <Buttons>
                            <ButtonCancelModal onClick={handleCreateCancel}>
                                Cancel
                            </ButtonCancelModal>
                            <ButtonSave
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Confirm
                                </Button>
                            </ButtonSave>
                        </Buttons>
                    </StyledForm>
                </ModalCreateContent>
            </ModalCreate>

            <TableWrapper>
                <Table>
                    <thead>
                    <Tr>
                        <Th>Username</Th>
                        <Th>Status</Th>
                        <Th>Logo</Th>
                        <Th>Disable/Enable</Th>
                        <Th></Th>
                    </Tr>
                    </thead>
                    <tbody>
                    {users.map((data, index) => {
                        return <TableRow key={index} data={data} setSwitch={setSwitch}
                                         showModalDelete={showModalDelete}
                                         showModalEdit={showModalEdit}/>;
                    })}
                    </tbody>
                </Table>
            </TableWrapper>

            <AddActionWrapper>
                <AddAction onClick={showModalCreate}>
                    New user
                </AddAction>
            </AddActionWrapper>

        </Component>
    );
}

export default TableUser;