import React from 'react';

function PlusInCircleSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
            <path d="M17.5002 31.1667C25.3242 31.1667 31.6668 24.824 31.6668 17C31.6668 9.17598 25.3242 2.83334 17.5002 2.83334C9.67613 2.83334 3.3335 9.17598 3.3335 17C3.3335 24.824 9.67613 31.1667 17.5002 31.1667Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 11.3333V22.6667" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.8335 17H23.1668" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default PlusInCircleSvg;