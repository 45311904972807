import React from 'react';
import styled from "styled-components";
import TopMenuUser from "../components/TopMenuUser";
import TableProject from "../components/TableProject";
import {scale} from "../config";

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(112px * ${scale});
`;

const PageContent = styled.div`
  max-width: calc(1180px * ${scale});
  width: 100%;
  margin-top: calc(110px * ${scale});
`;

function ProjectList() {
    return (
        <Page>
            <TopMenuUser type={"projectList"}/>
            <PageContent>
                <TableProject/>
            </PageContent>
        </Page>
    );
}

export default ProjectList;