export enum RoleType {
    admin = 90,
    user = 30
}

export type ProcessingSettingsType = {
    DefaultService: string,
    ReducingMethod: string,
    FigureHeight: number,
    DPI: number,
    FillingClosedGaps: boolean,
    Outline: number,
    OutlineColor: string,
    ClosedGapColor: string,
    FillColor: string,
    LogoUrl: string,
    PageSize: number[],
    PageMargins: number[],
    PrintResolution: number
}

export type UserType = {
    Login: string,
    Password: string,
    Email: string,
    Phone: string,
    Name: string,
    Role: RoleType,
    Enabled: boolean,
    UpdatedDate: string,
    CreatedDate: string
    ExpirationDate: string

    LogoUrl?: string
}

export type FigureType = {
    Id: number,
    Path?: string,
    Count: number
}

export type ProcessingType = {
    Id: number,
    Name: string,
    Enabled: boolean,
    CreatedDate: string,
    UpdatedDate: string,
    Figures: FigureType[]
}

export type SettingsType = {
    Name?: string,
    DefaultService?: string,
    Copies?: number,
    DPI?: number,
    FigureHeight?: number,
    Outline?: number,
    OutlineColor?: string,
    ClosedGapColor?: string,
    FillColor?: string,
    PageMargins?:  number[]
    Background?: string,

    FillingClosedGaps: boolean,
    ReducingMethod: string,
    PageSize: number[],
}

export type FormSettingsType = {
    ReducingMethod: string,
    Copies: number,
    FillingClosedGaps: boolean,
    Background: string,

    PageSize: string,

    Margins_Left_Right: number,
    Margins_Top_Bottom: number,
    Margins_Left: number,
    Margins_Top: number,
    Margins_Bottom: number,
    Margins_Right: number,

    CustomPageWidth: number,
    CustomPageHeight: number,
}

export type SizePapersType = {
    [key: string]: [number, number];
};
