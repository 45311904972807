import React from 'react';

function TrashCanSvg(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
            <path d="M10 20H16.6667H70" stroke="#AEC0A4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M63.3334 20V66.6667C63.3334 68.4348 62.631 70.1305 61.3808 71.3807C60.1306 72.631 58.4349 73.3333 56.6667 73.3333H23.3334C21.5653 73.3333 19.8696 72.631 18.6194 71.3807C17.3691 70.1305 16.6667 68.4348 16.6667 66.6667V20M26.6667 20V13.3333C26.6667 11.5652 27.3691 9.86953 28.6194 8.61929C29.8696 7.36904 31.5653 6.66667 33.3334 6.66667H46.6667C48.4349 6.66667 50.1306 7.36904 51.3808 8.61929C52.631 9.86953 53.3334 11.5652 53.3334 13.3333V20" stroke="#AEC0A4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.3333 36.6667V56.6667" stroke="#AEC0A4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M46.6667 36.6667V56.6667" stroke="#AEC0A4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default TrashCanSvg;